// .light-theme .blue_theme,
// .light-theme .purple_theme,
// .light-theme .orange_theme,
// .light-theme .green_theme,
// .light-theme .aqua_theme,
// .light-theme .cyan_theme,
// .dark-theme
// FIX: add theme before theme class ".cdk-overlay-container"
.light-theme,
.dark-theme {
  @import './buttons';
  @import './drawer';
  @import './form';
  @import './checkbox';
  @import './radio';
  @import './inputs';

  .topbar {
    background-color: $toolbar;
  }
}

.light-theme,
.dark-theme {
  @import './card';
  @import './typography';
}

@import './borderColor';
@import './table';
@import './sidenav';

@import './list';
@import './icon';

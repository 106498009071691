.empty-state {
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  h2 {
    margin: 0;
  }

  .empty-image {
    width: 190px;
    height: 190px;
    margin: 40px;
  }

  .empty-title {
    font-size: 24px;
  }

  .empty-description {
    padding: 0;
    color: #9a9fab;
  }
}

$translation: -15px; // 16px
$duration: 0.5s;

.transition-in {
  opacity: 0;
  // -webkit-transform: translateY($translation); // transition
  // transform: translateY($translation); // transition
  transition: opacity $duration, -webkit-transform $duration;
  transition: opacity $duration, transform $duration;
  transition: opacity $duration, transform $duration, -webkit-transform $duration;
  will-change: opacity, transform;
  &.is-active {
    opacity: 1;
    // -webkit-transform: translate(0); // transition
    // transform: translate(0); // transition
  }
}

.transition-delay-nth-1 {
  transition-delay: 0.25s;
}

.transition-delay-nth-2 {
  transition-delay: 0.5s;
}

.transition-delay-nth-3 {
  transition-delay: 0.75s;
}

.transition-delay-nth-4 {
  transition-delay: 1s;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

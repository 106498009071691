.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  background-color: #ffffffa8;
}

// .mdc-text-field--filled {
//   // when appearance="fill"
//   border-top-left-radius: 18px !important;
//   border-top-right-radius: 18px !important;
// }

// .mdc-text-field--outlined {
//   // when appearance="outline"
//   --mdc-outlined-text-field-container-shape: 18px !important;
// }

/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
  // color: #222;
  // font-size: 0.8em;
  // line-height: 1.4; // box-sizing: border-box;
  // height: 100%;
}

body {
  // font-family: $text-font-stack;
  // font-size: 15px;
  // line-height: 1.6;
  // color: #3e396b;
  // background-color: $background-color;
  // height: 100%; // font-family: 'Lato', sans-serif;
  // font-family: "Montserrat", Helvetica, Arial, sans-serif;
  // font-family: 'Roboto', sans-serif !important;
  // font-family: $default-font;
  // font-style: normal;
  // font-weight: 400;
  // color: #546b81; // font-weight: 300;
  // font-style: normal;
  // color: #3A4953;
  // font-size: 18px; //18px
  // background-color: $vt-white;
  // background-color: #f4f6f8; // spy
  // background-color: #fcfcfc;
  // background-color: #f3f4f9;
  // background-color: #f3f3f3;
  // background-color: $app-background;

  // font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  //   'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.dragover {
  background-color: #deedff !important;
  opacity: 0.8;
  border: solid;
  border-color: #313131;
  border-width: 2px;
  border-style: dashed;
}

p .vt-icon {
  margin-right: 6px;
  vertical-align: text-bottom;
}

.mono {
  font-family: Andale Mono, Monaco, Consolas, Lucida Console, monospace;
}

.highlighter {
  background-color: rgba(253, 216, 53, 0.6);
}

.link,
a {
  color: black; //$color-primary-dark;
  text-decoration: none;
  transition: color 0.3s;
}

textarea:focus,
input:focus {
  outline: none;
}

ol,
ul {
  font-size: 13px;
  line-height: 20px;
}

ul {
  padding-left: 0;
  list-style: none;
}

// button:active {
//   outline: none;
//   border: none;
// }

// button:focus {
//   outline: 0;
// }

button,
input,
optgroup,
select,
textarea {
  font-family: $default-font;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  height: 100%;
  margin: 0;
}

fieldset {
  border: 0;
}

a {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.disabled {
  color: #bdbdbd;
}

.type-md {
  font-size: 16px;
  line-height: 24px;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.txt-c,
.text--center {
  text-align: center;
}

.is-size-h1,
.is-size-h2,
.is-size-h3,
.is-size-h4,
h1,
h2,
h3,
h4 {
  font-family: $default-font;
  font-weight: 600;
  // color: #294661;
  margin: 0;
}

.is-size-h1,
h1 {
  margin-bottom: 5px;
  font-size: 1.7rem;
  line-height: 40px;
  letter-spacing: normal;
  font-weight: 700;
}

// h1 {
//     margin-bottom: 20px;
//     font-size: 32px;
//     line-height: 60px;
//     letter-spacing: -1px;
// }

h2 {
  margin-bottom: 0.5rem !important;
  font-size: 1.4rem;
  line-height: 30px;
  letter-spacing: -0.5px;
  font-weight: 700;
}

h3 {
  margin-bottom: 12px;
  font-size: 13px;
  line-height: 18px;
}

h4 {
  margin-bottom: 9px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  color: #546b81;
  text-transform: uppercase;
}

strong {
  font-weight: 600;
}

p {
  margin-bottom: 20px;
  // font-size: 15px;
  line-height: 20px; // font-size: 14px;
  // line-height: 1.5;
}

iframe {
  width: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*,
*::before,
*::after {
  box-sizing: inherit;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #dfd9d9;
  margin: 3em 0;
  // border-top: 1px solid #ccc;
  // margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  // vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  -webkit-clip-path: none;
  clip-path: none;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
  content: ' ';
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

$open: #1a6afc;
$sent: #19d263;
$dispatched: #19d263;
$received: rgb(136, 136, 136);
$cancelled: rgb(245, 50, 50);

.consignment-status {
  padding: 4px 8px;
  color: $open;
  border-radius: 10px;
  border: solid $open 1px;
  font-size: 0.9rem;

  &.open {
    color: $open;
    border-color: $open;
  }

  &.sent {
    color: $sent;
    border-color: $sent;
  }

  &.dispatched {
    color: $dispatched;
    border-color: $dispatched;
  }

  &.received {
    color: $received;
    border-color: $received;
  }

  &.cancelled {
    color: $cancelled;
    border-color: $cancelled;
  }
}

.mat-drawer {
  background-color: $sidebarbg; //  $background;
}

.mat-drawer-container {
  background-color: $background;
}

.mat-drawer-side {
  border-right-color: #efefef;
}

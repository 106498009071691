.mat-mdc-raised-button.mat-primary,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-raised-button.mat-warn,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-unelevated-button.mat-warn,
.mdc-fab.mat-primary,
.mdc-fab.mat-accent,
.mdc-fab.mat-warn {
  // color: white;
}

.mdc-button {
  // border-radius: $border-radius;
}

.mat-mdc-raised-button {
  box-shadow: $cardshadow;
}

.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-fab:hover,
.mat-mdc-fab:focus,
.mat-mdc-mini-fab:hover,
.mat-mdc-mini-fab:focus {
  box-shadow: none;
}

// .mat-button-toggle-button {
//   font-size: 14px;
// }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
}

.flex-column {
  flex-direction: column;
}

// // NOTE: temporal solution. slide-toogle not load styles
// .mat-mdc-slide-toggle {
//   --mdc-switch-selected-focus-state-layer-color: #122ae1;
//   --mdc-switch-selected-handle-color: #122ae1;
//   --mdc-switch-selected-hover-state-layer-color: #122ae1;
//   --mdc-switch-selected-pressed-state-layer-color: #122ae1;
//   --mdc-switch-selected-focus-handle-color: #122ae1;
//   --mdc-switch-selected-hover-handle-color: #122ae1;
//   --mdc-switch-selected-pressed-handle-color: #122ae1;
//   --mdc-switch-selected-focus-track-color: #bbccff;
//   --mdc-switch-selected-hover-track-color: #bbccff;
//   --mdc-switch-selected-pressed-track-color: #bbccff;
//   --mdc-switch-selected-track-color: #bbccff;
// }

.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 20px;
}

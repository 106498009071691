.form {
  position: relative;
  max-width: 100%;
  p {
    // font-size: 15px;
    margin-bottom: 1em;
    line-height: 1.5;
  }
  &.read-only label {
    // color: #b9b9b9; // black;
    font-size: 14px;
    font-weight: 600;
  }
}

.form .field {
  clear: both;
  margin: 0em 0em 1.2em;
}

.form .field:last-child,
.form .fields:last-child .field {
  margin-bottom: 0em;
}

.form .fields .field {
  clear: both;
  margin: 0em;
}

.form .fields {
  display: flex;
  flex-direction: row;
  margin: 0em -0.5em 0em;
  // margin: 0em -0.5em 1.2em;
  // margin: 0em -0.5em 1em;
}

.form .fields > .field {
  flex: 1;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

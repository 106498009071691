@import 'abstracts/core';

.cdx-block {
  padding: 0.4em 0;
}

// old

$imageBackground: #343648;

$textFontSize: 16px;

// ---------------- CODEX CUSTOM STYLE ------------------
.codex-editor {
  color: black;

  .ce-toolbar {
    .ce-toolbar__content {
      max-width: 100%;
    }
  }

  .ce-block__content {
    max-width: 100%;
  }

  .image-tool {
    .image-tool__image {
      background: $imageBackground;
    }
  }

  // List
  // .cdx-list__item {
  //   font-size: $textFontSize;
  // }

  // CheckList
  // .cdx-checklist__item {
  //   padding: 0;
  // }
}

.editor {
  // height: calc(100vh - 70px);
  // padding-right: 10px;
  // overflow-y: auto;
  // overflow-x: hidden;
}

// ---------------- VIEWER ------------------

.kiara-viewer {
  // padding: 0px 30px;
  color: black;
  // overflow: auto;
  padding-bottom: 10px;
  user-select: text;
  // height: calc(100vh - 70px);
  // padding: 0 10%;
  padding-bottom: 50px;
  background: white;

  h1,
  h2,
  h3 {
    padding: 1em 0;
    margin: 0;
    margin-bottom: -0.9em;
    line-height: 1.5em;
    outline: none;
    color: #1b2733;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    // line-height: 1.6em;
    // outline: none;
    // font-size: $textFontSize;
    // margin-top: 10px;
    // margin-bottom: 20px;

    // margin-top: 2em;
    // letter-spacing: -0.003em;
    // margin-bottom: -0.46em;
    // font-style: normal;
    // word-break: break-word;
  }

  // Link
  a {
    cursor: pointer;
    text-decoration: underline;
  }

  // Image
  .image {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;

    &.has-border {
      border: 1px solid #9999a0;
    }

    &.is-strech {
    }

    &.has-background {
      padding: 15px;
      background: $imageBackground;
      img {
        max-width: 60%;
        margin: 0 auto;
        display: block;
      }
    }
  }

  // list
  // ul {
  //   margin: 0;
  //   padding-left: 20px;
  //   outline: none;

  //   &.is-ordered {
  //     list-style: decimal;
  //   }

  //   &.is-unordered {
  //     list-style: disc;
  //   }

  //   li {
  //     font-size: $textFontSize;
  //     padding: 5.5px 0 5.5px 3px;
  //     line-height: 1.6em;
  //   }
  // }

  .embed {
    margin: 10px 0;
  }
}

@import 'abstracts/core';

$width: 180px;
$module-background-color: white; // #f8faff;

.module {
  display: flex;
  flex-direction: column;
  // background-color: white;
  width: 100%;
  height: 100%;

  .module-header {
    display: flex;
    align-items: flex-end;
    height: $toolbar-height; // 84px;
    // padding-top: 1em;
    // padding-left: 5%;
    // padding-right: 5%;
    width: 100%;
    // background-color: $white;
    z-index: 1;
    box-shadow: 0px -1px 9px #e5e5e5;

    .tab-wrapper {
      padding-left: 5%;
      padding-right: 5%;
    }

    h4 {
      margin: 0;
      color: #3d3d44;
      margin-bottom: 20px;
    }
  }

  .module-content {
    // width: calc(100% - $sidenav-width);
    width: 100%;
    /* padding: 84px 0px; */
    // height: calc(100vh);
    // height: calc(100vh - 84px);
    /* background: red; */
    // position: fixed;
    // overflow-y: scroll;
    // background-color: $module-background-color;
    top: 80px;
    /* right: 70.9px; */
    bottom: 0;
    /* left: 287px; */
  }
}

$maxWidth: 680px;

.panel-wrap {
  width: 100%;
  position: relative;
  // height: 100vh;
  // overflow: auto;
  background-color: white;

  // &.zero-border {
  //   .header {
  //     border-bottom: none;
  //   }
  // }

  &.has-border {
    .panel-header {
      border-bottom: solid #ececec 1px;
    }
  }

  .panel-header,
  // @deprecated .header
  ._header {
    // position: sticky;
    // top: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: solid #f2f2f2 1px;
    // box-shadow: 0 1px #63728229;
    // box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
    height: 70px;
    background: white;
    // border-bottom: solid #ececec 1px;
    z-index: 2;
    display: flex;
    justify-content: center;

    .header-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      z-index: 2;
      max-width: $maxWidth;
      margin: 0 64px;

      .icons {
        display: flex;
        padding: 0;

        &.right {
          .icon {
            margin-left: 20px;
            margin-right: 0px;
          }
        }

        .icon {
          cursor: pointer;
          // width: 24px;
          // height: 24px;
          margin-right: 20px;
          // padding: 6px;
          img {
            height: 22px;
            width: 22px;
          }
        }
      }

      h1,
      h2,
      h3 {
        margin: 0;
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .back-arrow {
        display: inline-block;
        margin-right: 10px;
        cursor: pointer;
        background: url(/assets/images/icons/back-arrow.svg) no-repeat;
        background-size: 51px 24px;
        height: 24px;
        width: 24px;
        opacity: 0.5;
        transition: opacity 200ms ease;
        // position: absolute;
        // left: -20px;
        // top: 6px;
      }
    }
  }

  .panel-body {
    position: relative;
    // height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;

    // padding: 0 10%;
    // max-width: $maxWidth;
    // margin: 0 64px;

    .panel-content {
      position: relative;
      // height: 100%;
      width: 100%;
      // padding: 0 10%;
      max-width: $maxWidth;
      margin: 0 64px;
    }
  }
}

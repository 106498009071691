@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');

// font
$font-family: 'Plus Jakarta Sans', sans-serif;
// $font-family: Nunito, Helvetica, Arial, sans-serif;

// light color variable
$sidebarbg: #f6f7fb; // f8f8ff ffffff;
$background: #ffffff; // f6f7fb fafafa; // f6f8ff;
$cardbg: white;
$toolbar: #f8f8f8;
$cardshadow: rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
$hoverBgColor: #f6f9fc;

// Dark color variable
$darksidebarbg: #2a3447;
$darkbackground: #2a3447;
$darkcardbg: #2a3447;
$darktoolbar: #2a3447;
$darkcardshadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
$darkborderColor: #333f55;
$darkformborderColor: #465670;
$darkhoverbgcolor: #333f55;
$darkthemelightprimary: #253662;
$darkthemelightaccent: #1c455d;
$darkthemelightwarning: #4d3a2a;
$darkthemelighterror: #4b313d;
$darkthemelightsuccess: #1b3c48;
$darkthemelightinfo: #1b2648;

// Sidenav
$sidenav-desktop: 270px !default;
$sidenav-mini: 200px !default;
$header-height: 4.5rem; // 70px !default;
$app-content-height: calc(100vh - #{$header-height});

//BorderColor
$borderColor: #e5eaef;
$borderformColor: #dfe5ef;

// custom
$primary: #5d87ff;
$accent: #49beff;
$warning: #ffae1f;
$error: #fa896b;
$success: #13deb9;
$info: #5d87ff;
$white: #ffffff;

$light: #f2f6fa;

$gray-100: #7c8fac;

$light-primary: #ecf2ff;
$light-accent: #e8f7ff;
$light-warning: #fef5e5;
$light-error: #fdede8;
$light-success: #e6fffa;
$light-info: #ecf2ff;

// layout
$boxedWidth: 1200px;

$border-radius: 7px;

// text color
$textPrimary: #2a3547;

:root {
  --site-nav-search-height: 3.2rem;
  --site-nav-gutter: 16px;
  --btn-height: 32px;
}

// .btn2--primary-alt {
//   --btn-bg-color: #ea4c89;
//   --btn-bg-color-hover: #ec5e95;
//   --btn-text-color: #fff;
//   --btn-text-color-hover: #fff;
//   --btn-border-color: var(--btn-bg-color);
//   --btn-border-color-hover: var(--btn-bg-color-hover);
// }

// .btn2--circle {
//   --btn-width: var(--btn-height);
//   --btn-padding: 0;
//   --btn-border-radius: 50%;
// }

// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@import '../variables';

// Note: Color palettes are generated from primary: 5d87ff, secondary: 2a35d4
$_palettes: (
  primary: (
    0: #000000,
    10: #00174c,
    20: #002979,
    25: #003391,
    30: #003daa,
    35: #0a48bf,
    40: #2355cc,
    50: #4370e6,
    60: #628aff,
    70: #8da8ff,
    80: #b4c5ff,
    90: #dbe1ff,
    95: #eff0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #00036b,
    20: #0008a8,
    25: #0713c2,
    30: #1e29cc,
    35: #2e3ad7,
    40: #3d48e3,
    50: #5865fd,
    60: #7b85ff,
    70: #9ca4ff,
    80: #bec2ff,
    90: #e0e0ff,
    95: #f1efff,
    98: #fbf8ff,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #2b122a,
    20: #422740,
    25: #4e324c,
    30: #5b3d58,
    35: #674864,
    40: #745470,
    50: #8e6c8a,
    60: #a986a4,
    70: #c5a0bf,
    80: #e2bbdb,
    90: #ffd6f7,
    95: #ffebf8,
    98: #fff7fa,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1b1b1f,
    20: #303034,
    25: #3b3b3f,
    30: #46464a,
    35: #525256,
    40: #5e5e62,
    50: #77767a,
    60: #919094,
    70: #acaaaf,
    80: #c7c6ca,
    90: #e4e2e6,
    95: #f2f0f4,
    98: #fbf8fd,
    99: #fefbff,
    100: #ffffff,
    4: #0d0e11,
    6: #131316,
    12: #1f1f23,
    17: #292a2d,
    22: #343438,
    24: #39393c,
    87: #dbd9dd,
    92: #eae7ec,
    94: #efedf1,
    96: #f5f3f7,
  ),
  neutral-variant: (
    0: #000000,
    10: #191b23,
    20: #2e3038,
    25: #393b43,
    30: #45464f,
    35: #51525b,
    40: #5d5e67,
    50: #757680,
    60: #8f909a,
    70: #aaaab4,
    80: #c6c6d0,
    90: #e2e1ec,
    95: #f0f0fa,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
      // use-system-variables: true,
    ),
    typography: (
      // use-system-variables: true,
      // plain-family: 'Nunito, Helvetica, Arial, sans-serif',
      // brand-family: 'Nunito, Helvetica, Arial, sans-serif',
      plain-family: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
      brand-family: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
      bold-weight: 900,
      medium-weight: 500,
      regular-weight: 400,
    ),
    density: (
      scale: -2,
    ),
  )
);

:root {
  font-family: $font-family;
  font-size: 0.9rem; /* Ajustar el tamaño de fuente */
  // line-height: 1.4;
  // letter-spacing: 0.05em; /* Ajustar el espaciado entre letras */
  // font-weight: 500; /* Cambiar el grosor de la fuente */

  --mat-table-row-item-outline-width: 0.5px;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.06);
}

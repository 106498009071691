// @todo: add support for all browsers
.mat-drawer-inner-container::-webkit-scrollbar,
.scrollable::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

.mat-drawer-inner-container::-webkit-scrollbar-track,
.scrollable::-webkit-scrollbar-track {
  background: rgb(250, 250, 250);
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(241, 241, 241);
  //   outline: 1px solid slategrey;
}

.mat-drawer-side {
  border-right: solid 1px rgb(246, 244, 244);
  z-index: 1 !important;
}

.mat-drawer-container {
  background-color: #ffffff;
  margin: 0 auto;
  // max-width: 1400px;
  position: relative !important;
  height: 100vh;
}

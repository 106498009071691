@font-face {
  font-family: Colfax;
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/colfax/colfax-light.woff2') format('woff2'),
    url('/assets/fonts/colfax/colfax-light.woff') format('woff');
}

@font-face {
  font-family: Colfax;
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/colfax/colfax-regular.woff2') format('woff2'),
    url('/assets/fonts/colfax/colfax-regular.woff') format('woff');
}

@font-face {
  font-family: Colfax;
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/colfax/colfax-medium.woff2') format('woff2'),
    url('/assets/fonts/colfax/colfax-medium.woff') format('woff');
}

// @font-face {
//   font-family: styleguideicons;
//   font-style: normal;
//   font-weight: 400;
//   src: url('/assets/fonts/icons/style-guide-icons.ttf') format('truetype');

// src: url(../fonts/icons/style-guide-icons.eot);
// src: url(../fonts/icons/style-guide-icons.eot#iefix) format("embedded-opentype"), url(../fonts/icons/style-guide-icons.ttf) format("truetype"), url(../fonts/icons/style-guide-icons.woff) format("woff"), url(../fonts/icons/style-guide-icons.svg#style-guide-icons) format("svg")
// }

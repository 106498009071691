.section {
  // padding: 2em 5%;
  width: 100%;
  margin: 0 auto;
  // max-width: 1200px;
  padding-right: 5%; // 3%
  padding-left: 5%; // 3%
}

.page {
  width: 100%;
  // max-width: 1920px;
  // min-width: $screen-md-min;
  // height: 100vh;

  height: $app-content-height;
  //height: 100vh;
  overflow: auto;

  display: flex;
  flex-direction: column;
  // border-top-left-radius: 2rem;
  background-color: $background;

  .header {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $background;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    // position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
    // padding-right: 3%;
    // padding-left: 3%;
    transition: all 0.15s ease;
    border-bottom: solid transparent 1px;

    width: 100%;
    // margin: 0 auto;
    // max-width: 1000px;

    .section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &.sticky-border {
      border-bottom: solid #f2f2f2 1px;
    }

    &.sticky {
      // padding-top: 1em;
      // box-shadow: 1px 2px 22px 0px #00000014;
      // box-shadow: 1px 2px 15px -1px #00000012;
      // border-bottom: solid #f2f2f2 1px;
      box-shadow: 1px 2px 7px -4px rgba(0, 0, 0, 0.0705882353);
      border-bottom: solid #ebebeb 1px;
      h1 {
        // font-size: 1.5rem;
      }
    }

    .tab-wrapper {
      width: 100%;
      margin-top: 0.5em;
    }

    .title {
      position: relative;
      flex-grow: 1;
    }

    h1 {
      max-width: 100%;
      margin-bottom: 0.2em;
      display: flex;
      align-items: center;
      transition: all 0.15s ease;
      font-size: 1.6rem;
      // font-size: 1.7rem;
      line-height: 1;

      .badge {
        font-size: 16px;
        border-radius: 18px;
        color: #0751c8;
        padding: 6px 8px;
        margin-left: 1em;
        border: 1px #0751c8 solid;
        display: flex;
        align-items: center;
      }
    }
    p {
      margin: 0%;
      // font-size: 14px;
      color: #505050;
      font-weight: 400;
      margin-top: 0.5rem;
    }
    a {
      //   color: $color-primary;
      // font-size: 14px;
    }

    .back-arrow {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      background: url(/assets/icons/back-arrow.svg) no-repeat;
      background-size: 42px 18px;
      height: 24px;
      width: 24px;
      opacity: 0.8;
      transition: opacity 200ms ease;
      position: absolute;
      left: -30px;
      // top: 2px;
    }
  }

  .body,
  .content {
    flex-grow: 1;
    padding-top: 2em;
    padding-bottom: 2em;
    // overflow-y: auto;

    // padding-right: 3%;
    // padding-left: 3%;

    width: 100%;
    // margin: 0 auto;
    // max-width: 1000px;
  }

  &.has-tabs {
    .header {
      padding-bottom: 0;
    }
    .body {
      padding-top: 2em;
    }
  }
}

@include md {
  .page > .header {
    flex-direction: column;
    align-items: start;
  }

  .page > .header .btn-list {
    margin-top: 20px;
  }

  .page > .header {
    .back-arrow {
      position: relative;
      left: 0;
      top: 0;
    }
  }
}

.page-root {
  // background-color: white;
  // border-top-left-radius: 2rem;
  .page {
    height: calc(100vh - $header-height - 40px); // - topbar - tabbar
    // height: calc(100vh - 4.5rem - 40px); // - topbar - tabbar
  }
}

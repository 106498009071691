/**
 * Basic typography style for copy text
 */

@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&family=Inter:wght@300;400;500;700;800&family=Nunito:wght@200;400;700;800;900&display=swap');

body {
  // @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,600;0,700;0,800;0,900;1,700&display=swap');
  // @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900");
  // @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900"); //color: $text-color;
  // font: normal 125% / 1.4 $text-font-stack;

  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Nunito:ital,wght@0,200;0,400;0,600;0,700;0,800;0,900;1,700&display=swap');

  // @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
}

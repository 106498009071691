// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin colfax($font-weight) {
  font-family: $colfax-font;
  font-weight: $font-weight;
}

@mixin btn-shadow() {
  box-shadow: 0 1px 4px 0 rgba($sg-blue, 0.2);
}

@mixin icon-font-base() {
  // Styleguide
  // font-family: $icon-font;
  // font-size: 16px;

  // Awesome
  font-family: $icon-font;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
}

@mixin container() {
  display: block;
  max-width: 100% !important;
  width: 1127px;
  margin-left: auto !important;
  margin-right: auto !important;
}

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/*
 * Media queries
 *
 * Para añadir multiples tamaños, usa en orden descendente
 * Ej. @import lg{.el{}} @import sm{.el{}}
*/

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: #{$screen+'px'}) {
    @content;
  }
}

// Web first
@mixin mobile {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

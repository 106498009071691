/*
 * Atomic styles
 */

.highlight {
  background: #fff8b6;
  //padding: 0px 4px;
  //border-radius: 5px;
}

.txt-stroke {
  text-decoration: line-through;
}

.list-header {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: solid #ececec 1px;
}

.divider {
  margin: 1em 0;
  background-color: $slate_05;
  width: 100%;
  height: 2px;
}

.type-md {
  font-size: 16px;
  line-height: 24px;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.align-left {
  text-align: left !important;
}

.text-capitalize {
  text-transform: lowercase;
  text-transform: capitalize;
}

.text-center {
  text-align: center;
  padding: 0 1em;
}

.bold {
  font-weight: bold;
}

.caps {
  text-transform: uppercase;
}

.full-width {
  width: 100%;
}

// Flexbox

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  > :nth-child(2) {
    flex-grow: 1;
  }
}

.flex-justify-between {
  justify-content: space-between;
}

.no-padding {
  padding: 0 !important;
}

.flex-grow-first {
  display: flex;
  // justify-content: center;
  :first-child {
    // flex-grow: 1;
  }
  &.align-end {
    // align-items: end;
  }
}

.flex-align-center {
  align-items: center;
}

.flex-justify-end {
  justify-content: end;
}

.justify-between {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

@for $i from 1 through 6 {
  .flex-#{$i} {
    flex: $i;
  }
}

.txt-c {
  text-align: center;
}

.is-center {
  text-align: center;
}

.small {
  font-size: 13px;
  line-height: 20px;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
}

a.link {
  text-decoration: underline;
}

// h1 {
//     font-weight: 400;
//     line-height: 40px;
//     font-size: 30px;
// }
// h2,
// .h2 {
//     font-weight: 300;
//     line-height: 40px;
//     position: relative;
//     font-size: 30px;
// }
// h3,
// h3 {
//     font-weight: 500;
//     line-height: 40px;
//     font-size: 18px;
// }
.sp__1 {
  display: block;
  height: 10px;
}

.sp__2 {
  display: block;
  height: 30px;
}

.sp__3 {
  display: block;
  height: 60px;
}

.sp__4 {
  display: block;
  height: 120px;
}

.sp__5 {
  display: block;
  height: 240px;
}

.sp__bottom {
  display: block;
  height: 240px;
}

/* Colors */

.c__blue {
  color: $blue-color; //#1f8ceb;
}

.c__light-grey {
  color: #8da2b5;
}

.t__h1,
.t__h1-bigger,
.t__h1-big {
  color: $blue-color; // #1f8ceb;
  font-weight: 400;
  font-size: 34px; // text-align: center;
  line-height: 1.3;
  letter-spacing: -0.015em;
  margin: -10px auto 17px;
}

.t__h2 {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: -0.015em;
  line-height: 1.3;
}

.t__h5 {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 650px;
  letter-spacing: -0.01em;
  line-height: 1.3;
}

.t__h6 {
  font-weight: 300;
  font-size: 16px;
  margin: 0 auto 20px;
  max-width: 650px;
  letter-spacing: -0.01em;
  line-height: 1.3;
}

.t__smallest {
  font-size: 12px;
}

._section,
._section.section__no-scale {
  padding: 60px 0;
}

.u__relative {
  position: relative;
}

.u__o-hidden {
  overflow: hidden;
}

.btn__primary {
  background: linear-gradient(284deg, #5166fb, #5975ff);
  border-color: #1271c4;
  color: white;
}

@mixin atomic--lg() {
}

// md-select {
//   background-color: white;
//   color: black;
//   border: 1px solid #ffffff;
// }

// ::ng-deep .mat-form-field.mat-focused .mat-form-field-underline {
//   display: none;
// }

.mat-form-field-underline {
  display: none;
}

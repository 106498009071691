.btn-list-horizontal {
  display: flex;
  flex-direction: column;
  > .btn + .btn {
    margin-top: 12px;
  }
}

.btn-list {
  // font-size: 0;
  > .btn + .btn,
  > ns-icon + ns-icon {
    margin-left: 12px;
  }
}

.btn {
  position: relative;
  display: inline-block;
  padding: 7px 12px;
  line-height: 16px;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: $border-radius;
  box-sizing: border-box;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  user-select: none;

  &.is-disabled,
  &.is-btn-disabled {
    color: $white;
    pointer-events: none;
    cursor: default;
    background-color: $steel;
    border-color: $steel;
    &.btn-secondary,
    &.btn-danger {
      color: $steel;
      background-color: $white;
      border-color: $steel;
    }
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.has-icon,
  &.btn-with-icon,
  &.has-badge {
    padding-right: 18px;
    padding-left: 18px;
  }
  &.is-loading {
    pointer-events: none;
  }
  .vt-icon,
  .sg-loader,
  .badge {
    display: block;
    float: left;
    margin-right: 6px;
  }
  .badge {
    margin-bottom: 0;
  }
}

.btn-large {
  color: $white;
  background-color: $sg-blue-80;
  border-color: $sg-blue;
  font-size: 18px;
  font-weight: 400;
  padding: 20px 15px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  width: 100%;
  text-align: center;
  &:focus {
    opacity: 0.9;
  }
}

.btn-large-disabled {
  background: #e8e8e8 !important;
  color: #b9b9b9;
  cursor: default;
  border: solid #e8e8e8 3px;
}

.btn-primary {
  color: $white;
  background-color: $sg-blue-80;
  border-color: $sg-blue;
  &:hover,
  &:focus,
  &.is-active {
    color: $white;
    background-color: $sg-blue;
  }
  &:active {
    @include btn-shadow;
    color: $white;
  }
  &.has-badge .badge {
    color: $sg-blue;
    background-color: $white;
  }
}

.btn-secondary {
  color: $sg-blue;
  background-color: $white;
  border-color: $sg-blue-80;
  &:hover,
  &:focus,
  &.is-active {
    color: $sg-blue;
    background-color: $sg-blue-10;
  }
  &:active {
    @include btn-shadow;
    background-color: $sg-blue-10;
  }
  &.has-badge .badge {
    color: $white;
    background-color: $sg-blue;
  }
}

.btn-tertiary {
  color: black; // $slate-80;
  // background-color: $white;
  // border: 0;
  border-color: rgba(0, 0, 0, 0.62);
  &:hover,
  &:focus {
    color: $slate;
  }
  &.has-badge .badge {
    background-color: $slate-80;
  }
}

.btn-danger {
  color: $white;
  background-color: rgba($ron-burgundy, 0.8);
  border-color: $ron-burgundy;
  &:hover,
  &:focus,
  &.is-active {
    color: $white;
    background-color: $ron-burgundy;
  }
  &:active {
    @include btn-shadow;
    color: $white;
  }
  &.has-badge .badge {
    color: $ron-burgundy;
    background-color: $white;
  }
}

.btn-small {
  padding: 6px 18px;
  font-weight: normal;
}

.btn-dropdown {
  padding-right: 30px;
  padding-left: 18px;
  &::before {
    @include icon-font-base;
    position: absolute;
    top: 12px;
    right: 13px;
    color: inherit;
    content: $icon-caret;
  }
  &.btn-small {
    padding-right: 30px;
    padding-left: 20px;
    &::before {
      top: 7px;
      right: 10px;
    }
    &.btn-dropdown-gear {
      padding: 0 15px 0 10px;
    }
    &.btn-dropdown-gear::before {
      top: 3px;
      right: 1px;
    }
  }
}

.btn-dropdown-gear {
  border: 0;
  &.is-active > .vt-icon {
    transform: rotate(135deg);
  }
  &::before {
    top: 14px;
    font-size: 16px;
    color: $slate-60;
  }
  &.btn-primary::before,
  &.btn-primary > .vt-icon {
    color: $white;
  }
  > .vt-icon {
    display: block;
    font-size: 18px;
    color: $slate-60;
    transition: transform 0.5s;
  }
}

.btn-on-dark.btn-secondary {
  color: $white;
  background-color: transparent;
  border-color: rgba($white, 0.6);
  &:hover,
  &:focus,
  &.is-active {
    background-color: rgba($white, 0.1);
    border-color: $white;
  }
  &.is-disabled,
  &.is-btn-disabled {
    color: $steel;
    background-color: transparent;
    border-color: $steel;
    &:hover,
    &:focus {
      background-color: inherit;
    }
  }
  &.has-badge .badge {
    color: $slate;
    background-color: $white;
  }
}

.btn-on-dark.btn-primary.is-disabled,
.btn-on-dark.btn-primary.is-btn-disabled {
  background-color: rgba($steel, 0.5);
  border-color: $steel;
}

.btn-group {
  display: inline-block;
  padding: 4px;
  border: 1px solid $sg-blue;
  border-radius: 2px;
  > .btn-group-item {
    float: left;
    padding: 6px 18px;
    margin: 0;
    color: $sg-blue;
    background-color: $white;
    // border-color: rgba($sg-blue, 0.8);
    border: 1px solid transparent;
    &:hover {
      background-color: rgba($sg-blue, 0.1);
    }
    &:active {
      @include btn-shadow;
    }
    // &:first-child {
    //     border-top-right-radius: 0;
    //     border-bottom-right-radius: 0;
    // }
    // &:last-child {
    //     margin-left: -1px;
    //     border-bottom-right-radius: 0;
    //     border-top-left-radius: 0;
    // }
    // &:not(:first-child):not(:last-child) {
    //     margin-left: -1px;
    //     border-radius: 0;
    // }
    &.is-active {
      color: $white;
      background-color: $sg-blue-80;
      border-color: $sg-blue;
    }
  }
}

.btn-group-stacked {
  &.is-inline {
    display: flex;
    flex-direction: row;
    .btn-group-item {
      margin: 10px 2px;
    }
  }

  &.is-small {
    .input-checkbox-wrap,
    .input-radio-wrap {
      margin-bottom: 5px;
    }
    .btn-selectable {
      padding: 10px;
    }
  }

  .btn-group-item {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}

.btn-selectable {
  padding: 18px;
  color: $slate-80;
  text-align: left;
  border-color: $slate-20;
  &:hover {
    border-color: $sg-blue-40;
  }
  &.is-active {
    border-color: $sg-blue;
  }
  &.has-icon,
  &.btn-with-icon {
    .vt-icon,
    img {
      position: absolute;
      top: 50%;
      left: 51px;
      width: 25px;
      height: 25px;
      margin-top: -13px;
      font-size: 25px;
    }
    .input-checkbox-label,
    .input-radio-label,
    .btn-content {
      margin-left: 76px;
    }
  }
  .input-checkbox-label,
  .input-radio-label,
  .btn-content {
    margin-left: 33px;
    line-height: 1.3;
  }
  .input-checkbox-label,
  .input-radio-label {
    position: static;
    font-weight: bold;
    color: $slate-80;
    &::before {
      position: absolute;
      top: 50%;
      left: 18px;
      margin-top: -8px;
      margin-right: 0;
      margin-left: 0;
    }
  }
  [type='checkbox']:checked + .input-checkbox-label::after,
  [type='radio']:checked + .input-radio-label::after {
    position: absolute;
    top: 50%;
    left: 23px;
    margin-top: -3px;
    margin-left: 0;
  }
}

.input-checkbox-wrap,
.input-radio-wrap {
  margin-bottom: 20px;

  .input-info {
    display: block;
    margin-bottom: 0;
    margin-left: 21px;
  }
}

[type='checkbox'],
[type='radio'] {
  position: absolute;
  margin: 0;
  opacity: 0;

  &:checked + .input-checkbox-label::before,
  &:checked + .input-radio-label::before {
    background-color: $sg-blue;
    border-color: rgba($slate, 0.2);
  }

  &:checked + .input-checkbox-label::after,
  &:checked + .input-radio-label::after {
    position: absolute;
  }

  &:checked + .input-checkbox-label::after {
    @include icon-font-base;
    top: 3px;
    left: 3px;
    font-size: 9px;
    color: $white;
    content: '\f00c'; // content: $icon-check;
  }

  &:checked + .input-radio-label::after {
    top: 5px;
    left: 5px;
    width: 5px;
    height: 5px;
    background-color: $white;
    border-radius: 5px;
    content: '';
  }

  &:checked:focus + .input-checkbox-label::before,
  &:checked:focus + .input-radio-label::before {
    border-color: rgba($slate, 0.6);
  }

  &:disabled + .input-checkbox-label,
  &:disabled + .input-radio-label {
    color: $steel;
  }

  &:disabled + .input-checkbox-label::before,
  &:disabled + .input-radio-label::before {
    background-color: rgba($steel, 0.2);
    border-color: rgba($steel, 0.4);
  }

  &:focus + .input-checkbox-label::before,
  &:focus + .input-radio-label::before {
    border-color: $sg-blue;
  }
}

.input-checkbox-label,
.input-radio-label {
  position: relative;
  display: inline-block;
  margin-left: 21px;
  font-size: 13px;
  line-height: 18px;
  color: $slate;
  cursor: pointer;

  &::before {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 6px;
    text-indent: 0;
    vertical-align: top;
    background: $white;
    border: 1px solid $slate-20;
    content: '';
  }

  &::before,
  &::after {
    margin-left: -21px;
  }

  &:focus::before {
    border-color: $sg-blue;
  }
}

.input-checkbox-label {
  &::before {
    border-radius: $border-radius;
  }
}

.input-radio-label {
  &::before {
    border-radius: 50%;
  }
}

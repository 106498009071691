@mixin table-label($value) {
  color: $value;
  &::before {
    background-color: $value;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table-wrap {
  background-color: transparent !important;
  width: 100%; // anular el ancho máximo de la aplicación que causa algunos problemas con las tablas de checkbox
  max-width: none;
  margin-bottom: 20px;
  color: black;
  padding: 0 5px;
  border-radius: 3px;
  // background: white;
  // box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .field {
    margin: 0px !important;
  }

  &.has-checkboxes {
    width: calc(100% + 30px);
    margin-left: -30px;
  }
  tr {
    // font-size: 14px;
    line-height: 20px;
    // border: 1px solid $slate-10;
    border-bottom: 1px solid $slate-10;

    border-top: 0;
    transition: background-color 0.3s;
    // no colorear las celdas de checkbox cuando se seleccionan las filas
    &.is-selected td:not(.table-checkbox) {
      background-color: $slate-05;
    }
    // Mostrar checkboxes en hover/is-selected
    &:hover .input-checkbox-wrap,
    &.is-selected .input-checkbox-wrap {
      visibility: visible;
    }
    // ocultar los puntos suspensivos en hover
    &:hover .vt-icon-ellipsis {
      opacity: 0;
    }
    // Mostrar los iconos de acciones en hover
    &:hover .action-icons {
      opacity: 1;
    }
    &:hover .editor-type,
    &.is-selected .editor-type {
      display: block;
    }
    &:last-of-type {
      border-bottom: 1px solid $slate-10;
    }
    &.is-disabled td {
      background-color: $slate-05;
    }
    &.is-danger {
      color: rgb(225, 20, 20);
    }
  }
  td,
  th {
    padding: 8px 8px 8px 0px;
    // padding: 15px 12px;
    // padding: 20px 12px;
    // padding: 18px 20px;
    &.table-checkbox {
      border-color: transparent;
    }
    &.table-checkbox:hover,
    &.table-checkbox.is-selected {
      background-color: transparent;
    }
    &.with-border {
      border-left: 1px solid $slate-10;
    }
    &.align-center {
      text-align: center;
    }
    &.actions,
    &.align-right {
      text-align: right;
    }
  }
  td {
    .input {
      border: none;
      width: 100%;
      &::placeholder {
        color: #b6b8c0;
      }
      &.is-email {
        color: $color-primary;
      }
    }

    word-break: break-all;
    vertical-align: middle;
    p {
      margin-bottom: 0;
      // font-size: 13px;
      line-height: 20px;
    }
    &.table-checkbox {
      border-right-color: $slate-10;
    }
    &:not(.table-checkbox) {
      // background-color: $vt-white;
    }
    &.contact,
    &.campaign-name,
    &.list-name {
      font-weight: 600;
    }
    &.campaign-name p {
      position: relative;
    }
    &.campaign-name .vt-icon {
      margin: 0;
      cursor: help;
    }
    .editor-type {
      position: absolute;
      left: -20px;
      display: none;
      line-height: 1;
    }
    &.is-verified .vt-icon {
      color: $mantis;
    }
    &.is-not-verified .vt-icon {
      color: $ron-burgundy;
    } // Establecer la posición relativa  para mantener el ancho dinámico.
    .categories {
      position: relative;
      display: inline-flex;
    }
  }
  th {
    // font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 1px;
    color: #393939; // $slate-80;
    text-align: left;
    // text-transform: uppercase;
    vertical-align: middle;
    &.sort {
      cursor: pointer;
    }
    &.sort-asc,
    &.sort-desc {
      font-weight: 600;
      color: $slate;
    }
    &.sort-asc::after,
    &.sort-desc::after {
      @include icon-font-base;
      font-size: 20px;
      color: $sg-blue;
      vertical-align: middle;
    }
    &.sort-asc::after {
      content: $icon-sort-asc;
    }
    &.sort-desc::after {
      content: $icon-sort-desc;
    } // mostrar siempre el <th> checkbox
    .input-checkbox-wrap {
      visibility: visible;
    } //elimine el espacio de la parte superior e inferior del botón para reducir la altura de <th>
    .btn-dropdown {
      z-index: 1;
      padding-top: 0;
      padding-bottom: 0;
    } // Evita que el engranaje salte unos pocos píxeles al girar
    .vt-icon-gear {
      letter-spacing: 0;
    } // Ajusta la posición del engranaje.
    .btn-dropdown-gear::before {
      top: 0;
    } // Ajustes al menú desplegable para <th>
    .dropdown-menu {
      right: 0;
      left: auto;
      letter-spacing: 0;
      text-transform: none;
    }
  }

  // eliminar bordes y elementos de fondo en las filas <thead>
  thead tr {
    border-color: transparent;
    // background: $color-primary-light;
    // border: 1px solid $slate-10;
  }

  // ancho automático si no quieres tablas de ancho 100%
  &.is-auto-width {
    width: auto;
  }

  &.is-bordered {
    background: white;
    tr {
      border: 1px solid $slate-10;
    }
  }

  &.is-lite {
    margin-bottom: 0px;
    tr {
      border: none;
      td {
        padding: 0px 12px 0px 0px;
      }
    }
  }

  &.zero-border {
    tr {
      border: none;
    }
  }

  &.row-clickable,
  &.is-row-clickable {
    tbody tr:not(.table-detail-row) {
      cursor: pointer;
      &:hover:not(.is-selected) td {
        // background-color: #fcfcfc;
      }
    }
  }

  &.has-header {
    thead tr {
      background: $color-primary-light;
    }
  }

  &.has-footer {
    tr {
      &:last-of-type {
        background: $color-primary-light;
      }
    }
  }

  // apretar el espacio entre rows
  &.is-thin {
    td {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  // striped rows
  &.is-striped {
    tr:nth-child(even) td:not(.table-checkbox) {
      background-color: $slate-02;
    }
  }

  // with image icon
  &.with-avatar,
  &.has-avatars {
    td {
      padding: 13px 13px;
    }
  }

  // checkbox cells
  .table-checkbox {
    width: 30px;
    padding-right: 0;
    padding-left: 0;
    border: 1px solid $white;
    border-right: 1px solid $slate-10;
  }
  // filas que tienen una relación con un conjunto de filas debajo de ellas
  .segment-item {
    .cell-label {
      width: 120px;
      padding-left: 40px;
      border-top: 1px solid $white;
    } // Conector de puntos para mostrar relaciones entre filas
    .cell-label::before {
      position: absolute;
      top: -20px;
      left: 16px;
      width: 6px;
      height: 51px;
      border-bottom: 1px dashed $slate-20;
      border-left: 1px dashed $slate-20;
      content: '';
    }
    .label {
      margin-left: 9px;
    }
  } // ocultar los checkbox de fila por defecto
  .input-checkbox-wrap {
    margin-bottom: 0;
    visibility: hidden;
  }
  .badge {
    margin-bottom: 0;
    line-height: 10px;
  }
  .label + .date {
    padding-left: 21px;
  }
  .name,
  .info,
  .code,
  .description,
  .date,
  .email,
  .address,
  .phone,
  .subject,
  .error,
  .danger,
  .warning,
  .success {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 300px;
  }

  .code,
  .info,
  .description,
  .date {
    // font-size: 13px;
    color: $slate-80;
    font-size: 0.9rem;
    margin-top: 0px;
  }
  .danger {
    color: $color-danger;
  }
  .warning {
    color: $color-warning;
  }
  .success {
    color: $color-success;
  }
  .name,
  .email {
    // font-weight: 600;
  }
  .tag {
    font-size: 0.8rem;
    color: $slate-40;
  }
  .subject {
    font-weight: 400;
  }
  .error {
    color: $color-danger;
  }

  .expiration {
    background: #fddc22;
    border-radius: 6px;
    padding: 3px 8px;
    color: #1f1b1b;
  }

  .stats {
    position: relative;
    &.delivered .stats-line {
      background-color: $stats-delivered;
    }
    &.opens .stats-line {
      background-color: $stats-opens;
    }
    &.unique-opens .stats-line {
      background-color: $stats-unique-opens;
    }
    &.clicks .stats-line {
      background-color: $stats-clicks;
    }
    &.unique-clicks .stats-line {
      background-color: $stats-unique-clicks;
    }
    &.unsubscribes .stats-line {
      background-color: $stats-unsubscribes;
    }
    &.spam-reports .stats-line {
      background-color: $stats-spam-reports;
    }
  }
  .stats-line {
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
  }
  .vt-icon {
    vertical-align: middle;
    transition: opacity 0.3s;
  }
  .vt-icon-ellipsis {
    font-size: 20px;
    opacity: 1;
  }
  .actions {
    min-width: 100px;
    position: relative;
  }
  .action-icons {
    position: absolute;
    top: 50%;
    right: 6px;
    display: inline-flex;
    margin-top: -10px;
    opacity: 0;
    transition: opacity 0.3s;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
}

// Mover table-state hasta el fondo de la tabla
.table-wrap + .table-state {
  margin: -20px -1px 20px 30px;
  border-top: 0;
}

.table-wrap.has-checkboxes + .table-state {
  margin: -20px 0 20px;
}

.table-state {
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  background-color: $slate-02;
  border: 1px solid $slate-10; // aumentar padding para elementos vacíos
  &.is-empty {
    padding: 60px;
    margin-bottom: 30px;
    background-color: #ffffff;
    border-radius: $border-radius;
    svg {
      // ed
      g {
        stroke: #d8d8d8;
      }
      margin: 20px;
    }
  }
  &.is-empty .vt-icon {
    display: block;
    margin-bottom: 20px;
    font-size: 40px;
  }
  p {
    max-width: 720px;
    margin: 0 auto 20px;
    font-size: 14px;
    line-height: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .btn-list {
    margin-top: 20px;
    .vt-icon {
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}

.cell-label {
  // position: relative;
  .label {
    position: relative;
    display: inline-block;
    margin-left: 21px;
    font-size: 11px; // 12px
    font-weight: 600; // normal
    line-height: 20px; // label dots
    &::before {
      position: absolute;
      top: 3px;
      left: -21px;
      width: 12px;
      height: 12px;
      background-color: $slate-20;
      border-radius: 50%;
      content: '';
    }
    &-sent,
    &-delivered {
      @include table-label($mantis);
    }
    &-opened {
      @include table-label($mantis);
    }
    &-scheduled {
      @include table-label($cobalt);
    }
    &-pick-winner {
      @include table-label($wild-strawberry);
    }
    &-draft,
    &-global {
      @include table-label($steel);
    }
    &-list,
    &-progress {
      @include table-label($bluebird);
    }
    &-segment {
      @include table-label($seafoam);
    }
    &-bounced,
    &-canceled,
    &-paused {
      @include table-label($ron-burgundy);
    }
    &-optimized {
      @include table-label($burnt-orange);
    }
    &-ab-test {
      display: block;
      font-size: 11px;
      line-height: 15px;
    } // eliminar el punto de estado para las etiquetas de prueba a / b
    &-ab-test::before {
      content: none;
    }
  }
}

.compare-table-wrap {
  font-size: 13px;
  tr {
    &:hover td {
      background: $slate-05;
    }
    &.no-highlight:hover td {
      background: transparent;
    }
    &.spacer {
      height: 12px;
    }
    &.spacer td {
      padding: 0;
    }
    &.is-top td {
      font-weight: 600;
    }
    &.is-bottom td {
      padding-bottom: 20px;
    }
    &.is-bottom .val {
      border-bottom: 1px solid $compare-border-color;
    }
  }
  th {
    padding: 1em;
    font-size: 12px;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    background: $sg-blue;
    &.alt {
      background: $mantis;
    }
    &.transparent {
      background: transparent;
    }
  }
  td {
    padding: 14px 10px;
    &.val {
      text-align: center;
      border-left: 1px solid $compare-border-color;
    }
    &.val.val-alt {
      border-right: 1px solid $compare-border-color;
      border-left: 1px solid $compare-border-color;
    }
  }
}

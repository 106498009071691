.cdx-list {
  margin: 0;
  // padding-left: 40px;
  outline: none;

  padding-left: 20px !important;

  &__item {
    padding: 5.5px 0 5.5px 3px;
    line-height: 1.6em;

    font-size: $ce-font-size;
  }

  &--unordered {
    list-style: disc;
  }

  &--ordered {
    list-style: decimal;
  }

  &-settings {
    display: flex;

    .cdx-settings-button {
      width: 50%;
    }
  }
}

@use '@angular/material' as mat;
@use './themecolors/m3-theme';

@include mat.core();

html {
  // Apply the light theme by default
  @include mat.core-theme(m3-theme.$light-theme);
  // @include mat.button-theme(m3-theme.$light-theme);

  @include mat.all-component-themes(m3-theme.$light-theme);
  @include mat.system-level-colors(m3-theme.$light-theme);
  @include mat.system-level-typography(m3-theme.$light-theme);
  @include mat.color-variants-backwards-compatibility(m3-theme.$light-theme);
}

// // @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'variables';
@import 'layouts/transitions';
@import 'helpers/color';
@import 'helpers/icon-size';

// //Theme colors

// @import 'themecolors/default_theme';
// @include mat.all-component-themes($bluetheme);

// .dark-theme {
//   @include mat.all-component-colors($darkbluetheme);
// }

// // .dark-theme .aqua_theme {
// //   @include mat.all-component-colors($darkaquatheme);
// // }

// .orange_theme {
//   @import 'themecolors/orange_theme';
//   @include mat.all-component-colors($orangetheme);
// }

// .aqua_theme {
//   @import 'themecolors/aqua_theme';
//   @include mat.all-component-colors($aquatheme);
// }

// .purple_theme {
//   @import 'themecolors/purple_theme';
//   @include mat.all-component-colors($purpletheme);
// }

// .green_theme {
//   @import 'themecolors/green_theme';
//   @include mat.all-component-colors($greentheme);
// }

// .cyan_theme {
//   @import 'themecolors/cyan_theme';
//   @include mat.all-component-colors($cyantheme);
// }

//container layout
@import 'overrides/materialoverrides';
@import 'dark/dark';
@import 'container';
@import 'layouts/layouts';
@import 'grid/grid';
@import 'helpers/custom-flex';
@import 'helpers/flexbox';
@import 'helpers/index';

@import 'base/index';
@import 'components/index';

// horizontal
// @import 'horizontal/horizontal';

// apps
// @import 'apps/calendar';
// @import 'apps/email';

// pages
// @import 'pages/dashboards';
@import 'pages/auth';
// @import 'pages/landingpage';

// RTL Theme
// @import 'rtl/rtl';

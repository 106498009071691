// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");

// font

// Fonts
$nunito: Nunito, Helvetica, Arial, sans-serif;
$montserrat-font: 'Montserrat', Helvetica, Arial, sans-serif;
$colfax-font: Colfax, Helvetica, Arial, sans-serif;
$icon-font: 'Font Awesome 5 Free'; // styleguideicons;
$plusJakartaSans: 'Plus Jakarta Sans', sans-serif;
$openSans: 'Open Sans', sans-serif;

$font-family: $colfax-font;
$default-font: $font-family;

/* Colors */

$primary-color: #1a6afc;
$secondary-color: #165fe7;

$icon-normal: #2c2828;
$icon-active: $primary-color;

// Sidenav
$sidenav-item-margin-left: 15px;

/* Colors */

// $app-background: #f8faff;
$app-background: #ffffff;

/* Sizes */

// Header
$sidenav-width: 18rem; // 200px
$main-header-size: 4.5rem;

$small-size: 15px;
/// Regular font family
/// @type List
$text-font-stack: 'Montserrat', Helvetica, Arial, sans-serif !default;
$montserrat-font: 'Montserrat', Helvetica, Arial, sans-serif;
/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;
/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;
/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

$menu-background-color: white;
$background-color: white;

$primary-main-color: #5b00d7;
$primary-color: #5e36ff;
$secondary-color: rgb(56, 86, 253);
$blue-color: rgb(31, 140, 235);
// $blue-color:  #5e36ff;
/// Container's maximum width
/// @type Length
$max-width: 1180px !default;
/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;
/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

/*=======================================================
 * Media queries sizes
=======================================================*/

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
$screen-sm-max: 575px;
// Small tablets (portrait view)
$screen-md-min: 799px; //768px;
$screen-md-max: 800px; //767px;
// Tablets and small desktops
$screen-lg-min: 992px;
$screen-lg-max: 991px;
// Large tablets and desktops
$screen-xl-min: 1200px;
$screen-xl-max: 1199px;
// Extra large desktops
$screen-xxl-min: 1400px;
$screen-xxl-max: 1399px;

// Kiara

$border-radius: 8px; //2px;

// modular scale base
$scale-base: 12px;

// modular scale up
$scale-base-up-01: 13px;
$scale-base-up-02: 15px;
$scale-base-up-03: 18px;
$scale-base-up-04: 20px;
$scale-base-up-05: 27px;
$scale-base-up-06: 30px;
$scale-base-up-07: 40px;
$scale-base-up-08: 45px;
$scale-base-up-09: 60px;
$scale-base-up-10: 90px;

// modular scale down
$scale-base-down-01: 9px;
$scale-base-down-02: 6px;
$scale-base-down-03: 3px; // 4b71fc blue

// primary colors
$primary-color: #0751c8; //#18c96e;
$secondary-color: #050505; //#050505;

$sg-blue: #0751c8; // #18c96e
$color-primary: #0751c8; // #050505;
$color-primary-dark: #0a44a3;
$color-secondary: #1d3645;
$color-primary-light: #f7f9fa;
$color-danger: #e72f2f;
$color-warning: #ffb326;
$color-success: #37de7c;

// $sg-blue:             #3c6cd8;
$slate: #1b2b3a; // #294661

// primary color shades
$sg-blue-80: #1a6afc; // #18c96e;
// $sg-blue-80:          #5883fd;
$sg-blue-60: #18c96e; // #76ee9a;
$sg-blue-40: #a3f3c2;
$sg-blue-20: #d1f9d3;
$sg-blue-10: #e8fced;
$sg-blue-05: #f3fdf4;
$sg-blue-02: #fbfffc;

$slate-80: #546b81;
$slate-60: #7f90a0;
$slate-40: #a9b5c0;
$slate-20: #d4dadf;
$slate-10: #e9ecef;
$slate-05: #f4f6f7;
$slate-02: #fbfbfc;

// Custom colors
$vt-white: #ffffff; // #fafafa; //#f3f4f9;

// charting colors
$ron-burgundy: #b71c1c;
$burnt-orange: #ff5722;
$carrot: #f90;
$mango: #fdd835;
$mantis: #18c96e;
$spruce: #4cb04f;
$grass-stain: #8ac24a;
$lime: #cbdb39;
$cobalt: #303f9f;
$bluebird: #2196f3;
$seafoam: #00bcd4;
$caribbean: #00e5ff;
$crown: #7b1fa2;
$screample: #7c4dff;
$sangria: #880e4f;
$lavender: #dea7e8;
$wild-strawberry: #ff4081;
$steel: #9e9e9e;
$white: #fff;
$black: #000;

// charting metrics
$stats-delivered: $grass-stain;
$stats-opens: $cobalt;
$stats-unique-opens: $bluebird;
$stats-clicks: $crown;
$stats-unique-clicks: $screample;
$stats-unsubscribes: $sangria;
$stats-spam-reports: $ron-burgundy;

// logo accent colors
$logo-accent-light: #99e1f4;
$logo-accent-medium: #00b3e3;
$logo-accent-dark: #009dd9;

// icons
$icon-airplane: '\e90b';
$icon-airplane-fill: '\e912';
$icon-align-center: '\e927';
$icon-align-left: '\e928';
$icon-align-right: '\e929';
$icon-analytics: '\e915';
$icon-automation: '\e939';
$icon-builder: '\e914';
$icon-button: '\e920';
$icon-calendar: '\e917';
$icon-caret: '\e900';
$icon-check: '\e905';
$icon-check-circle: '\e907';
$icon-check-thin: '\e938';
$icon-clean-ui: '\e916';
$icon-clock: '\e90d';
$icon-code: '\e919';
$icon-columns: '\e923';
$icon-contacts: '\e913';
$icon-contacts-alt: '\e92c';
$icon-copy: '\e902';
$icon-create: '\e901';
$icon-csv: '\e92a';
$icon-desktop-view: '\e627';
$icon-divider: '\e91a';
$icon-drag: '\e91b';
$icon-editor-code: '\e934';
$icon-editor-design: '\e935';
$icon-editor-old: '\e933';
$icon-ellipsis: '\e90e';
$icon-export: '\e931';
$icon-gear: '\e608';
$icon-help: '\e932';
$icon-image: '\e91e';
$icon-images: '\e924';
$icon-image-text: '\e922';
$icon-info-circle: '\e903';
$icon-key: '\e918';
$icon-list: '\e92b';
$icon-locked: '\e91d';
$icon-mail: '\e61d';
$icon-mail-search: '\e92d';
$icon-mobile-view: '\e61c';
$icon-pencil: '\e624';
$icon-people: '\e601';
$icon-pie-chart: '\e606';
$icon-plus: '\e617';
$icon-reload: '\e926';
$icon-save-draft: '\e911';
$icon-search: '\e906';
$icon-segment: '\e609';
$icon-social: '\e925';
$icon-sort-asc: '\e910';
$icon-sort-desc: '\e90f';
$icon-spacer: '\e91f';
$icon-status-caution: '\e92f';
$icon-status-negative: '\e930';
$icon-status-positive: '\e92e';
$icon-teammate: '\e90c';
$icon-test-data: '\e937';
$icon-text: '\e921';
$icon-text-only: '\e603';
$icon-trash: '\e62b';
$icon-unlocked: '\e91c';
$icon-unsubscribe: '\e936';
$icon-user-security: '\e90a';
$icon-view: '\e600';
$icon-warning: '\e909';
$icon-warning-triangle: '\e904';
$icon-x: '\e908';
$icon-x-legacy: '\e62e';

// alert-success
$alert-success: #f1f7e9;
$alert-success-text: #2e6b30;
$alert-warning: #fefae6;
$alert-warning-text: #8c6c15;
$alert-danger: #f4dddd;
$alert-danger-text: #b71c1c;
$alert-verification: $mantis;
$alert-trial: $slate;

// modals
$modal-height: 665px;
$modal-width: 525px;
$modal-footer-height: 71px;
$center-modal-width: 600px;
$center-modal-position: -($center-modal-width / 2);
$modal-body-height: $modal-height - $modal-footer-height;

// segment terms
$segment-term-max-width: 1279px;

// tables
$compare-border-color: $slate-10;

// toolbar
$toolbar-height: 60px;

// tooltips
$tooltip-bg: fade-out($slate, 0.1); // not work with direct color;
$tooltip-font-size: 12px;
$tooltip-line-height: 18px;
$tooltip-arrow-height: 6px;

// z-index
$dropdown-menu-z-index: 1000;
$spotlight-circle-z-index: 10000;
$spotlight-tooltip-z-index: 10010;
$modal-index: 20000;
$info-popup-z-index: 19000;
$switch-background-z-index: 100;
$switch-selector-z-index: 110;
$switch-option-z-index: 120;

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

// color map for generating color and bg-color classes
$colors: (
  vt-blue: $sg-blue,
  vt-blue-80: $sg-blue-80,
  vt-blue-60: $sg-blue-60,
  vt-blue-40: $sg-blue-40,
  vt-blue-20: $sg-blue-20,
  vt-blue-10: $sg-blue-10,
  vt-blue-05: $sg-blue-05,
  vt-blue-02: $sg-blue-02,
  slate: $slate,
  slate-80: $slate-80,
  slate-60: $slate-60,
  slate-40: $slate-40,
  slate-20: $slate-20,
  slate-10: $slate-10,
  slate-05: $slate-05,
  slate-02: $slate-02,
  ron-burgundy: $ron-burgundy,
  burnt-orange: $burnt-orange,
  carrot: $carrot,
  mango: $mango,
  mantis: $mantis,
  spruce: $spruce,
  grass-stain: $grass-stain,
  lime: $lime,
  cobalt: $cobalt,
  bluebird: $bluebird,
  seafoam: $seafoam,
  caribbean: $caribbean,
  crown: $crown,
  screample: $screample,
  sangria: $sangria,
  lavender: $lavender,
  wild-strawberry: $wild-strawberry,
  steel: $steel,
  logo-accent-light: $logo-accent-light,
  logo-accent-medium: $logo-accent-medium,
  logo-accent-dark: $logo-accent-dark,
);

@each $name, $value in $colors {
  .color-#{$name} {
    color: $value;
  }
}

@each $name, $value in $colors {
  .bg-color-#{$name} {
    background-color: $value;
  }
}

// Editor
$ce-font-size: 17px;

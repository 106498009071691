.tab-wrapper {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $slate-10;
  height: 40px; //$toolbar-height;

  // font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  &.is-header {
    margin-bottom: 15px;
    .tab {
      padding: 0px 0px 10px;
      margin-right: 30px;
      border-bottom: 0;
    }
  }

  &.is-centered {
    justify-content: center;
  }

  &.zero-border {
    border-bottom: 0;

    // eliminar el margen negativo en zero-border
    .tab {
      margin-bottom: 0;
    }
  }

  &.zero-padding {
    padding: 0;

    .tab {
      padding: 0;
    }
  }

  &.is-editor,
  &.is-ab-testing {
    .tab {
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    }

    &.is-3-up .tab {
      width: 33.3333%;
    }

    &.is-4-up .tab {
      width: 25%;
    }
  }

  &.is-ab-testing {
    &.is-2-up .tab {
      width: 50%;
    }

    &.is-5-up .tab {
      width: 20%;
    }

    &.is-6-up .tab {
      width: 16.6667%;
    }

    .tab {
      background-color: $slate-05;
      border-right: 1px solid $slate-10;
      transition: background-color 0.3s;
    }

    .tab:hover {
      background-color: $slate-02;
    }

    .tab:last-of-type {
      border-right: 0;
    }

    .tab.is-active {
      background-color: $white;
      border-bottom: 0;
    }
  }
}

.tab {
  display: flex;
  align-items: center;
  // menos 2px en la parte inferior para tener en cuenta los bordes
  // padding: 20px 8px 24px;
  // padding: 20px 30px 18px;
  // margen negativo para mover los estados activos hacia abajo para cubrir el borde del wrapper
  margin-bottom: -1px;
  // font-size: 17px;
  font-weight: normal;
  line-height: 20px;
  margin-right: 16px;
  color: #54575d; // #3a4953;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #86909a;
    // padding: 20px 8px 24px;
  }

  &.is-active {
    color: $sg-blue;
    border-bottom: 3px solid $sg-blue;
    // font-weight: 600;
  }

  &.is-disabled {
    pointer-events: none;
    cursor: default;
    color: $steel;
    // background-color: $white;
    border-color: $steel;
  }

  .badge {
    height: 16px;
    margin: 2px 0;
    line-height: 1;
  }
}

// TODO: eliminar
.tab-active {
  color: $sg-blue;
  border-bottom: 2px solid $sg-blue;
}

.tab-number {
  @include colfax(600);
  min-width: 20px;
  height: 20px;
  margin-left: 12px;
  color: $slate-80;
  text-align: center;
  background-color: $slate-10;
  border-radius: 15px;
  font-size: 0.8rem;
  padding: 0 6px;
}

// license: http://goo.gl/gmx05L
// scss-lint:disable PseudoElement, VendorPrefix, SelectorFormat, SelectorDepth, BemDepth
// propiedades de linting deshabilitadas debido a que es una hoja de estilo de terceros modificada

$min-height: 38px;

.select2-container {
  position: relative;
  display: block;
  // font-size: 13px;
  // border-bottom: 1px solid $slate-20;
  outline: 0;
  box-shadow: transparent 0 1px 0;
  transition: 0.3s border-color, 0.3s box-shadow;

  display: block;
  line-height: 24px;
  color: #1b2b3a;
  background-color: transparent;
  box-sizing: border-box;
  margin: 0;
  padding: 0px 10px;
  width: 100%;
  outline: 0;
  border: 2px solid #e7e5e8;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: none;
  color: #27252a;
  word-break: normal;
  font-weight: 400;
  cursor: text;
  transition: all 0.2s;
  transition-property: border-color, box-shadow;
  border-radius: 8px;

  min-height: $min-height;

  [type='search'] {
    -webkit-appearance: textfield;
  }

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-input-placeholder {
    color: $slate-40;
  }

  :-moz-placeholder {
    color: $slate-40;
  }

  ::-moz-placeholder {
    color: $slate-40;
  }

  :-ms-input-placeholder {
    color: $slate-40;
  }

  .select2-selection--single {
    display: block;
    cursor: pointer;
    outline: 0;
    -webkit-user-select: none;
    user-select: none;

    height: 30px;
    padding-top: 4px;

    .select2-selection__placeholder {
      color: $slate-40;
    }

    .select2-selection__clear {
      right: 16px;
    }

    .select2-selection__arrow::before {
      // @include icon-font-base; // @edit
      position: absolute;
      top: 5px;
      right: 0;
      color: $slate-60;
      // content: $icon-caret; // @edit
    }

    .select2-selection__arrow b {
      display: none;
    }
  }

  .select2-selection--multiple {
    display: block;
    // min-height: $min-height;
    outline: 0;
    -webkit-user-select: none;
    user-select: none;

    .select2-selection__placeholder {
      color: $slate-60;
    }

    .select2-selection__clear {
      right: 0;
    }

    .select2-selection__choice {
      float: left;
      padding: 4px;
      margin-top: 2px;
      margin-right: 6px;
      font-size: 1rem;
      line-height: 18px;
      color: $white;
      cursor: default;
      background-color: $sg-blue;
      border-radius: $border-radius;
    }

    .select2-selection__choice__remove {
      display: inline-block;
      margin-right: 6px;
      font-weight: 600;
      color: $white;
      cursor: pointer;

      background: none;
      border: 0;
    }
  }

  .select2-selection__rendered {
    display: inline;
    padding: 0;
    margin: 0;
    overflow: hidden;
    line-height: 24px;
    color: $slate;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;
    outline: 0;
  }

  .select2-selection__clear {
    position: absolute;
    top: 9px;
    width: 27px;
    color: $slate-60;
    text-align: center;
    cursor: pointer;
    border: 0;
    background: none;

    &:hover {
      color: $slate;
    }
  }

  .select2-search--dropdown {
    .select2-search__field {
      border: 0;
      border-bottom: 1px solid $slate-20;
    }
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      padding: 0;
      margin-top: 5px;
      font-size: 100%;
      border: 0;
    }

    .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-container--focus {
    // border-bottom-color: $sg-blue;
    // box-shadow: $sg-blue 0 1px 0;
    border-color: $sg-blue;
  }

  &.select2-container--disabled {
    border-color: rgba($steel, 0.4);
    // border-bottom-color: rgba($steel, 0.4);
    box-shadow: transparent 0 1px 0;

    ::-webkit-input-placeholder {
      color: rgba($steel, 0.4);
    }

    :-moz-placeholder {
      color: rgba($steel, 0.4);
    }

    ::-moz-placeholder {
      color: rgba($steel, 0.4);
    }

    :-ms-input-placeholder {
      color: rgba($steel, 0.4);
    }

    .select2-selection--single,
    .select2-selection--multiple {
      cursor: default;
    }

    .select2-selection__choice {
      background-color: $slate-40;
    }

    .select2-selection__placeholder,
    .select2-selection__arrow::before {
      color: rgba($steel, 0.4);
    }

    .select2-selection__clear,
    .select2-selection__choice__remove {
      display: none;
    }
  }

  .select2-results {
    .select2-results__options {
      max-height: 234px;
      overflow-y: auto;
    }

    .select2-results__options--nested {
      max-height: 100%;
    }
  }

  .select2-results__option[role='group'] {
    padding: 0;
  }

  .select2-results__option[aria-disabled='true'] {
    color: $steel;
  }

  .select2-results__option--highlighted[aria-selected],
  .select2-results__option[aria-selected='true'],
  .select2-results__option--highlighted[aria-selected='true'] {
    color: $slate;
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $slate-10;
  }

  .select2-results__option[aria-selected='true'],
  .select2-results__option--highlighted[aria-selected='true'] {
    background-color: $slate-20;
  }

  .option-filter {
    display: inline-block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    border-radius: 100%;

    &.option-filter-delivered {
      background-color: $mantis;
    }

    &.option-filter-processed {
      background-color: $slate-20;
    }

    &.option-filter-not-delivered {
      background-color: $ron-burgundy;
    }
  }
}

.select2-dropdown {
  position: absolute;
  left: -100000px;
  z-index: 1051;
  display: block;
  width: 100%;
  background-color: $slate-02;
  border: 1px solid rgba($slate, 0.1);
  border-radius: $border-radius;
  box-shadow: 0 2px 6px 0 rgba($slate, 0.1);
}

.select2-results {
  display: block;
}

.select2-results__group {
  @include colfax(600);
  display: block;
  padding: 9px 30px;
  color: $slate;
  cursor: default;
}

.select2-results__options {
  padding: 0;
  margin: 0;
  list-style: none;
}

.select2-results__option {
  padding: 10px 16px;
  line-height: 18px;
  font-size: 1rem;
  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open {
  // target the select, not the options dropdown
  &.select2 {
    // border-bottom-color: $sg-blue;
    // box-shadow: $sg-blue 0 1px 0;
    border-color: $sg-blue;
  }

  .select2-dropdown {
    left: 0;
  }
}

.select2-search__field {
  background-color: transparent;
}

.select2-search--dropdown {
  display: block;
  padding: 12px;
  background-color: $white;
  border-bottom: 1px solid $slate-10;

  &:before {
    @include icon-font-base;
    position: absolute;
    top: 21px;
    right: 18px;
    z-index: 1;
    color: inherit;
    pointer-events: none;
    content: $icon-search;
  }

  &.select2-search--hide {
    display: none;
  }

  .select2-search__field {
    position: relative;
    width: 100%;
    height: 25px;
    padding: 0;
    // font-size: 13px;
    line-height: 25px;
    color: $slate-60;
  }

  .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.select2-close-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-color: $white;
  border: 0;
  opacity: 0;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
}

.input-select-wrap.has-tooltip {
  // add spacing to account for info icon width
  .select2-container {
    padding-right: 18px;
  }

  // hide carets if there is an info icon w/ tooltip
  .select2-selection__arrow {
    display: none;
  }
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 0px solid #aaa;
  border-radius: 4px;
}

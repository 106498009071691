.main-content {
  margin-top: 40px;
}

.toolbar-container {
  width: 100%;
  max-width: 1920px;
  // min-width: $screen-md-min;
  padding-right: 5%;
  padding-left: 5%;
  //padding-top: 16px;

  .header-wrap {
    margin-bottom: 1em;
  }

  .tab-wrapper {
    // height: $tab-wrapper-height;
    .tab {
      // color: #b4b4de;
      &.is-active {
        // color: #111112;
        border-bottom: 0px solid #ef5c92;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1920px;
  // min-width: $screen-md-min;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 68px;
  // height: 100%;
  // height: calc(100% - #{$toolbar-height});
}

.class-container {
  width: 100%;
  max-width: 1920px;
  min-width: $screen-md-min;
  padding-right: 10%;
  padding-left: 10%;
  // padding-top: 60px;
  height: 100vh;
}

.page-content {
  //   max-width: 1140px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.center-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 20px;
  justify-content: center;
  margin: 0 auto;
  max-width: 450px;

  > div:first-child {
    margin: auto;
    width: 100%;
  }

  h1,
  h2,
  h3,
  p {
    text-align: center;
  }
  .btn-list {
    text-align: center;
  }
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
  position: relative;

  .back-arrow {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    background: url(/assets/icons/back-arrow.svg) no-repeat;
    background-size: 51px 24px;
    height: 24px;
    width: 24px;
    opacity: 0.5;
    transition: opacity 200ms ease;
    position: absolute;
    left: -30px;
    top: 6px;
  }
  .breadcrumb {
    flex-grow: 1;
    width: 100%;
  }

  .header {
    h1 {
      max-width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .badge {
        font-size: 16px;
        border-radius: 18px;
        color: #0751c8;
        padding: 6px 8px;
        margin-left: 1em;
        border: 1px #0751c8 solid;
        display: flex;
        align-items: center;
      }
    }

    h2 {
      margin-bottom: 6px;
    }

    p {
      margin: 0%;
      // font-size: 14px;
      color: #505050;
      font-weight: 400;
    }
    a {
      color: $color-primary;
      // font-size: 14px;
    }
  }

  // &.sticky {
  //   position: sticky;
  //   box-shadow: 0 0 5px 5px black;
  // }
}

@include md {
  .header-wrap {
    flex-direction: column;
  }

  .header-wrap .btn-list {
    margin-top: 20px;
  }
}

@include xl {
  .header-wrap {
    .back-arrow {
      position: relative;
      left: 0;
      top: 0;
    }
  }
}

.lot-discounts {
  margin-top: 0.3rem;
  display: block;
}

.lot-discount-item {
  padding: 0.2rem 0.7rem;
  margin-right: 1rem;
  background: #ff7169;
  border-radius: 0.6rem;
  color: white;
  &.is-deleted {
    background: #939393;
    color: white;
  }

  .actions {
    display: inline;
    justify-content: end;
    //padding: 0.4em 1em;
    .action-icon {
      margin-left: 1rem;
    }
    img {
      cursor: pointer;
      width: 14px;
    }
  }
}

$input-padding: 8px 10px;
$border-radius: 6px;

.badge {
  &.danger {
    color: $ron-burgundy;
  }
  &.warning {
    color: $carrot;
  }
}

.app-input {
  box-sizing: border-box;
  margin: 0;
  padding: $input-padding;
  width: 100%;
  outline: 0;
  border: 2px solid #e7e5e8;
  border-radius: $border-radius;
  background-color: #ffffff;
  box-shadow: none;
  color: #27252a;
  word-break: normal;
  font-weight: 400;
  cursor: text;
  transition: all 0.2s;
  transition-property: border-color, box-shadow;
  &:focus {
    border-color: $primary-color;
  }
}

input {
  &:focus,
  &:active {
    outline: 0;
  }
}

.input-info,
.input-text-info {
  display: inline-block;
  padding-top: 6px;
  font-size: 0.8rem;
  line-height: 15px;
  &.danger {
    color: $ron-burgundy;
  }
  &.warning {
    color: $carrot;
  }
}

.input-text-wrap {
  position: relative;
  padding-top: 1rem;
  // margin-bottom: 20px;
  &.is-required {
    label::after {
      position: absolute;
      top: 4px;
      right: -9px;
      width: 4px;
      height: 4px;
      background: $ron-burgundy;
      border-radius: 4px;
      content: '';
    }
  }
  &.without-label,
  &.no-label {
    padding-top: 0px;
  }
  &.is-focused,
  &.has-value {
    .input-text-label {
      // top: 0;
      // font-size: 14px;
      // line-height: 15px;
      top: -5px;
      font-size: 0.8rem;
      line-height: 15px;
      left: 0;
    }
    label::after {
      top: 4px;
    }
  }
  &.is-disabled {
    .input-text-label {
      color: rgba($steel, 0.4);
    }
    [type='text'],
    [type='password'],
    [type='number'],
    [type='email'],
    [type='tel'] {
      color: rgba($steel, 0.4);
      pointer-events: none;
      border-bottom-color: rgba($steel, 0.4);
    }
  }
  &.is-error,
  &.is-error.is-focused,
  &.is-error.has-value {
    [type='text'],
    [type='password'],
    [type='number'],
    [type='email'],
    [type='tel'] {
      border-bottom-color: $ron-burgundy;
      box-shadow: $ron-burgundy 0 1px 0;
    }
  }
  &.is-search {
    &::before {
      @include icon-font-base;
      position: absolute;
      top: 19px;
      right: 0;
      pointer-events: none;
      content: $icon-search;
    }
    &.is-focused::before {
      font-weight: bold;
      color: $sg-blue;
    }
  }
  &.is-calendar {
    &::before {
      @include icon-font-base;
      position: absolute;
      top: 19px;
      right: 0;
      pointer-events: none;
      content: $icon-calendar;
    }
    &.is-focused::before {
      color: $sg-blue;
    }
  }
  &.has-units {
    &[class*='has-arrow-']::before {
      @include icon-font-base;
      position: absolute;
      top: 19px;
      left: 0;
    }
    &.has-arrow-top::before {
      content: $icon-sort-asc;
    }
    &.has-arrow-right::before {
      content: $icon-sort-asc;
      transform: rotate(90deg);
    }
    &.has-arrow-bottom::before {
      content: $icon-sort-desc;
    }
    &.has-arrow-left::before {
      content: $icon-sort-asc;
      transform: rotate(-90deg);
    }
    &::after {
      position: absolute;
      top: 20px;
      right: 3px;
      font-size: 12px;
      content: attr(data-units);
    }
    [type='text'],
    [type='password'],
    [type='number'],
    [type='email'],
    [type='tel'] {
      padding-right: 20px;
    }
    &[class*='has-arrow-'] [type='text'],
    &[class*='has-arrow-'] [type='password'],
    &[class*='has-arrow-'] [type='number'],
    &[class*='has-arrow-'] [type='email'],
    &[class*='has-arrow-'] [type='tel'] {
      padding-left: 20px;
      text-align: right;
    }
  }
  &.is-large {
    .input-text-label {
      top: 15px;
      font-size: 18px;
      line-height: 27px;
    }
    [type='text'],
    [type='password'],
    [type='number'],
    [type='email'],
    [type='tel'] {
      font-size: 18px;
      line-height: 27px;
    }
  }
  &.is-large.is-focused,
  &.is-large.has-value {
    .input-text-label {
      top: 0;
      font-size: 12px;
      line-height: 15px;
    }
  }
  [type='text'],
  [type='password'],
  [type='number'],
  [type='email'],
  [type='tel'] {
    display: block;
    // width: 100%;
    // padding: 0;
    // font-size: 14px;
    // line-height: 20px;
    color: $slate;
    background-color: transparent;
    // border: 0;
    // border-bottom: 1px solid $slate-20;
    // box-shadow: transparent 0 1px 0;
    // transition: 0.3s border-color, 0.3s box-shadow;

    box-sizing: border-box;
    margin: 0;
    padding: $input-padding; // 11px 14px;
    width: 100%;
    outline: 0;
    border: 2px solid #e7e5e8;
    border-radius: $border-radius;
    background-color: #ffffff;
    box-shadow: none;
    color: #27252a;
    word-break: normal;
    font-weight: 400;
    cursor: text;
    transition: all 0.2s;
    transition-property: border-color, box-shadow;

    &:focus {
      border-color: $sg-blue;
      // box-shadow: $sg-blue 0 1px 0;
    }
    &:disabled {
      background: transparent;
    }
  }
}

.input-text-label {
  //color: $slate_60;
  color: #989ab4;
  position: absolute;
  // top: 18px;
  font-size: 0.9rem;
  top: 1.9rem;
  left: 1rem;
  display: inline-block;
  // font-size: 16px;
  line-height: 12px;
  pointer-events: none;
  transition: 0.3s all, 0.3s font-size;
  transition-timing-function: cubic-bezier(0.02, 0.01, 0.47, 1);
}

.input-info-tooltip {
  position: absolute;
  top: 19px;
  right: 0;
}

.input-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $slate-02;
  border: 1px solid $slate-10;
  border-radius: $border-radius;
  box-shadow: 0 2px 6px 0 rgba($slate, 0.1);
  .loading {
    display: none;
    &.is-visible {
      display: block;
    }
  }
}

.search-results-title,
.search-result {
  padding: 9px 18px 9px 30px;
  font-size: 13px;
  line-height: 18px;
}

.search-results-title {
  margin-bottom: 0;
  color: $slate;
  background: rgba($sg-blue, 0.1);
}

.search-results {
  padding: 0;
  margin: 0;
}

.search-result {
  overflow: hidden;
  color: $slate-80;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.3s color, 0.3s background-color;
  &:hover {
    color: $slate;
    cursor: pointer;
    background-color: $slate-05;
  }
}

input[type='time'] {
  padding: 11px 14px;
  border-radius: 4px;
  border: 2px solid #e7e5e8;
  border-radius: 4px;
  background-color: #ffffff;
  display: block;
  width: 100%;
}

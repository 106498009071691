.fill-remaining-space {
  /* This fills the remaining space, by using flexbox. 
     Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.app-content-height {
  height: $app-content-height;
}

html .topbar {
  background-color: $toolbar;
  position: sticky;
  top: 0;
  z-index: 9;
  height: $header-height;
  padding: 0 1rem;
}

.sidenav .app-logo,
.topbar .app-logo {
  width: $sidenav-mini;
  height: $header-height;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 2rem;
    width: 2rem;
  }
}

.sidenav .app-logo {
  width: 100% !important;
}

.topbar-dd {
  min-width: 360px !important;
}

.apps-dd {
  min-width: 400px !important;
  overflow: unset !important;

  .mat-mdc-menu-content {
    padding: 0;
  }
}

.upgrade-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
}

.object-cover {
  object-fit: cover;
}

@media (min-width: 768px) {
  .search-dialog {
    width: 600px;
  }
}

// perfect scroll
.ps__rail-y {
  right: 0;
  left: unset !important;
}

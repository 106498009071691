.mat-icon {
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
  svg {
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
  }
}

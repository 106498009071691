.micro {
  font-size: 12px;
  line-height: 1.50001;
  font-weight: initial;
}
.caption {
  font-size: 13px;
  line-height: 1.38463;
  font-weight: initial;
}
.normal {
  font-weight: 400;
}
.black {
  font-weight: 900;
}
.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.no_underline {
  text-decoration: none;
}
.lowercase {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.break_all {
  word-break: break-all;
}
.break_word {
  word-wrap: break-word;
}
.no_wrap {
  white-space: nowrap;
}
.code_wrap {
  white-space: pre;
  word-wrap: break-word;
}
.pre_wrap {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
}
.normal_wrap {
  white-space: normal;
}
.cjk_wrap {
  display: inline-block;
  white-space: nowrap;
}
.copy_only {
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: baseline;
  *vertical-align: auto;
  *zoom: 0;
  *display: inline;
  width: 1px;
  height: 0;
  background-size: 0;
  background-repeat: no-repeat;
  font-size: 0;
  color: transparent;
  float: left;
  text-rendering: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.float_none {
  float: none;
}
.clear_left {
  clear: left;
}
.clear_right {
  clear: right;
}
.clear_both {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.clearfix::after,
.clearfix::before {
  display: table;
  line-height: 0;
  content: '';
}
.clearfix::after {
  clear: both;
}
.position_relative {
  position: relative;
}
.position_fixed {
  position: fixed;
}
.position_absolute {
  position: absolute;
}
.position_absolute_top {
  position: absolute;
  top: 0;
}
.position_absolute_right {
  position: absolute;
  right: 0;
}
.position_absolute_bottom {
  position: absolute;
  bottom: 0;
}
.position_absolute_left {
  position: absolute;
  left: 0;
}
.offscreen {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
}
.block {
  display: block;
}
.inline_block {
  display: inline-block;
}
.inline_flex {
  display: inline-flex;
}
.inline {
  display: inline;
}
.display_none {
  display: none;
}
.border_box {
  box-sizing: border-box;
}
.content_box {
  box-sizing: content-box;
}
.align_left {
  text-align: left;
}
.align_center {
  text-align: center;
}
.align_right {
  text-align: right;
}
.align_top {
  vertical-align: top;
}
.align_middle {
  vertical-align: middle;
}
.align_bottom {
  vertical-align: bottom;
}
.align_text_bottom {
  vertical-align: text-bottom;
}
.full_width {
  width: 100%;
  max-width: 100%;
}
.half_width {
  width: 50%;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.full_height {
  height: 100%;
}
.full_max_width {
  max-width: 100%;
}
.no_max_width {
  max-width: none;
}
.no_min_width {
  min-width: 0;
}
.no_min_height {
  min-height: 0;
}
.auto_width {
  width: auto;
}
.cursor_default {
  cursor: default;
}
.cursor_pointer {
  cursor: pointer;
}
.margin_0 {
  margin: 0;
}
.margin_25 {
  margin: 4px;
}
.margin_50 {
  margin: 8px;
}
.margin_75 {
  margin: 12px;
}
.margin_100 {
  margin: 16px;
}
.margin_125 {
  margin: 20px;
}
.margin_150 {
  margin: 24px;
}
.margin_175 {
  margin: 28px;
}
.margin_200 {
  margin: 32px;
}
.margin_250 {
  margin: 40px;
}
.margin_300 {
  margin: 48px;
}
.margin_400 {
  margin: 64px;
}
.margin_500 {
  margin: 80px;
}
.margin_600 {
  margin: 96px;
}
.margin_top_0 {
  margin-top: 0;
}
.margin_top_25 {
  margin-top: 4px;
}
.margin_top_50 {
  margin-top: 8px;
}
.margin_top_75 {
  margin-top: 12px;
}
.margin_top_100 {
  margin-top: 16px;
}
.margin_top_125 {
  margin-top: 20px;
}
.margin_top_150 {
  margin-top: 24px;
}
.margin_top_175 {
  margin-top: 28px;
}
.margin_top_200 {
  margin-top: 32px;
}
.margin_top_250 {
  margin-top: 40px;
}
.margin_top_300 {
  margin-top: 48px;
}
.margin_top_400 {
  margin-top: 64px;
}
.margin_top_500 {
  margin-top: 80px;
}
.margin_top_600 {
  margin-top: 96px;
}
.margin_right_0 {
  margin-right: 0;
}
.margin_right_25 {
  margin-right: 4px;
}
.margin_right_25_negative {
  margin-right: -4px;
}
.margin_right_50 {
  margin-right: 8px;
}
.margin_right_50_negative {
  margin-right: -8px;
}
.margin_right_75 {
  margin-right: 12px;
}
.margin_right_100 {
  margin-right: 16px;
}
.margin_right_125 {
  margin-right: 20px;
}
.margin_right_150 {
  margin-right: 24px;
}
.margin_right_175 {
  margin-right: 28px;
}
.margin_right_200 {
  margin-right: 32px;
}
.margin_right_250 {
  margin-right: 40px;
}
.margin_right_300 {
  margin-right: 48px;
}
.margin_right_400 {
  margin-right: 64px;
}
.margin_right_500 {
  margin-right: 80px;
}
.margin_right_600 {
  margin-right: 96px;
}
.margin_bottom_0 {
  margin-bottom: 0;
}
.margin_bottom_25 {
  margin-bottom: 4px;
}
.margin_bottom_50 {
  margin-bottom: 8px;
}
.margin_bottom_75 {
  margin-bottom: 12px;
}
.margin_bottom_100 {
  margin-bottom: 16px;
}
.margin_bottom_125 {
  margin-bottom: 20px;
}
.margin_bottom_150 {
  margin-bottom: 24px;
}
.margin_bottom_175 {
  margin-bottom: 28px;
}
.margin_bottom_200 {
  margin-bottom: 32px;
}
.margin_bottom_250 {
  margin-bottom: 40px;
}
.margin_bottom_300 {
  margin-bottom: 48px;
}
.margin_bottom_400 {
  margin-bottom: 64px;
}
.margin_bottom_500 {
  margin-bottom: 80px;
}
.margin_bottom_600 {
  margin-bottom: 96px;
}
.margin_left_0 {
  margin-left: 0;
}
.margin_left_25 {
  margin-left: 4px;
}
.margin_left_50 {
  margin-left: 8px;
}
.margin_left_75 {
  margin-left: 12px;
}
.margin_left_100 {
  margin-left: 16px;
}
.margin_left_125 {
  margin-left: 20px;
}
.margin_left_150 {
  margin-left: 24px;
}
.margin_left_175 {
  margin-left: 28px;
}
.margin_left_200 {
  margin-left: 32px;
}
.margin_left_250 {
  margin-left: 40px;
}
.margin_left_300 {
  margin-left: 48px;
}
.margin_left_400 {
  margin-left: 64px;
}
.margin_left_500 {
  margin-left: 80px;
}
.margin_left_600 {
  margin-left: 96px;
}
.padding_0 {
  padding: 0;
}
.padding_25 {
  padding: 4px;
}
.padding_50 {
  padding: 8px;
}
.padding_75 {
  padding: 12px;
}
.padding_100 {
  padding: 16px;
}
.padding_125 {
  padding: 20px;
}
.padding_150 {
  padding: 24px;
}
.padding_175 {
  padding: 28px;
}
.padding_200 {
  padding: 32px;
}
.padding_250 {
  padding: 40px;
}
.padding_300 {
  padding: 48px;
}
.padding_400 {
  padding: 64px;
}
.padding_500 {
  padding: 80px;
}
.padding_600 {
  padding: 96px;
}
.padding_top_0 {
  padding-top: 0;
}
.padding_top_25 {
  padding-top: 4px;
}
.padding_top_50 {
  padding-top: 8px;
}
.padding_top_75 {
  padding-top: 12px;
}
.padding_top_100 {
  padding-top: 16px;
}
.padding_top_125 {
  padding-top: 20px;
}
.padding_top_150 {
  padding-top: 24px;
}
.padding_top_175 {
  padding-top: 28px;
}
.padding_top_200 {
  padding-top: 32px;
}
.padding_top_250 {
  padding-top: 40px;
}
.padding_top_300 {
  padding-top: 48px;
}
.padding_top_400 {
  padding-top: 64px;
}
.padding_top_500 {
  padding-top: 80px;
}
.padding_top_600 {
  padding-top: 96px;
}
.padding_right_0 {
  padding-right: 0;
}
.padding_right_25 {
  padding-right: 4px;
}
.padding_right_50 {
  padding-right: 8px;
}
.padding_right_75 {
  padding-right: 12px;
}
.padding_right_100 {
  padding-right: 16px;
}
.padding_right_125 {
  padding-right: 20px;
}
.padding_right_150 {
  padding-right: 24px;
}
.padding_right_175 {
  padding-right: 28px;
}
.padding_right_200 {
  padding-right: 32px;
}
.padding_right_250 {
  padding-right: 40px;
}
.padding_right_300 {
  padding-right: 48px;
}
.padding_right_400 {
  padding-right: 64px;
}
.padding_right_500 {
  padding-right: 80px;
}
.padding_right_600 {
  padding-right: 96px;
}
.padding_bottom_0 {
  padding-bottom: 0;
}
.padding_bottom_25 {
  padding-bottom: 4px;
}
.padding_bottom_50 {
  padding-bottom: 8px;
}
.padding_bottom_75 {
  padding-bottom: 12px;
}
.padding_bottom_100 {
  padding-bottom: 16px;
}
.padding_bottom_125 {
  padding-bottom: 20px;
}
.padding_bottom_150 {
  padding-bottom: 24px;
}
.padding_bottom_175 {
  padding-bottom: 28px;
}
.padding_bottom_200 {
  padding-bottom: 32px;
}
.padding_bottom_250 {
  padding-bottom: 40px;
}
.padding_bottom_300 {
  padding-bottom: 48px;
}
.padding_bottom_400 {
  padding-bottom: 64px;
}
.padding_bottom_500 {
  padding-bottom: 80px;
}
.padding_bottom_600 {
  padding-bottom: 96px;
}
.padding_left_0 {
  padding-left: 0;
}
.padding_left_25 {
  padding-left: 4px;
}
.padding_left_50 {
  padding-left: 8px;
}
.padding_left_75 {
  padding-left: 12px;
}
.padding_left_100 {
  padding-left: 16px;
}
.padding_left_125 {
  padding-left: 20px;
}
.padding_left_150 {
  padding-left: 24px;
}
.padding_left_175 {
  padding-left: 28px;
}
.padding_left_200 {
  padding-left: 32px;
}
.padding_left_250 {
  padding-left: 40px;
}
.padding_left_300 {
  padding-left: 48px;
}
.padding_left_400 {
  padding-left: 64px;
}
.padding_left_500 {
  padding-left: 80px;
}
.padding_left_600 {
  padding-left: 96px;
}
.no_border {
  border: none;
}
.no_border_top {
  border-top: none;
}
.no_border_bottom {
  border-bottom: none;
}
.no_border_left {
  border-left: none;
}
.no_border_right {
  border-right: none;
}
.bordered {
  border: 1px solid rgba(29, 28, 29, 0.13);
}
.border_top {
  border-top: 1px solid rgba(29, 28, 29, 0.13);
}
.border_right {
  border-right: 1px solid rgba(29, 28, 29, 0.13);
}
.border_bottom {
  border-bottom: 1px solid rgba(29, 28, 29, 0.13);
}
.border_left {
  border-left: 1px solid rgba(29, 28, 29, 0.13);
}
.inner_shadow {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}
.hidden {
  display: none;
  visibility: hidden;
}
.invisible {
  visibility: hidden;
}
.no_opacity {
  opacity: 0;
}
.half_opacity {
  opacity: 0.5;
}
.three_fourths_opacity {
  opacity: 0.75;
}
.display_flex {
  display: flex;
}
.align_items_center {
  align-items: center;
}
.align_items_baseline {
  align-items: baseline;
}
.align_items_start {
  align-items: flex-start;
}
.align_items_end {
  align-items: flex-end;
}
.align_self_center {
  align-self: center;
}
.align_self_start {
  align-self: flex-start;
}
.align_self_end {
  align-self: flex-end;
}
.align_self_stretch {
  align-self: stretch;
}
.justify_content_center {
  justify-content: center;
}
.justify_content_around {
  justify-content: space-around;
}
.justify_content_between {
  justify-content: space-between;
}
.justify_content_start {
  justify-content: flex-start;
}
.justify_content_end {
  justify-content: flex-end;
}
.flex_direction_column {
  flex-direction: column;
}
.flex_direction_row_reverse {
  flex-direction: row-reverse;
}
.flex_none {
  flex: none;
}
.flex_one {
  flex: 1;
}
.flex_wrap {
  flex-wrap: wrap;
}
.flex_nowrap {
  flex-wrap: nowrap;
}
.flex_shrink_none {
  flex-shrink: 0;
}
.nudge_bottom_1,
.nudge_bottom_2,
.nudge_bottom_3,
.nudge_left_1,
.nudge_left_2,
.nudge_left_3,
.nudge_right_1,
.nudge_right_2,
.nudge_right_3,
.nudge_top_1,
.nudge_top_2,
.nudge_top_3 {
  position: relative;
}
.nudge_top_1 {
  top: 1px;
}
.nudge_top_2 {
  top: 2px;
}
.nudge_top_3 {
  top: 3px;
}
.nudge_right_1 {
  right: 1px;
}
.nudge_right_2 {
  right: 2px;
}
.nudge_right_3 {
  right: 3px;
}
.nudge_bottom_1 {
  bottom: 1px;
}
.nudge_bottom_2 {
  bottom: 2px;
}
.nudge_bottom_3 {
  bottom: 3px;
}
.nudge_left_1 {
  left: 1px;
}
.nudge_left_2 {
  left: 2px;
}
.nudge_left_3 {
  left: 3px;
}
.overflow_hidden {
  overflow: hidden;
}
.overflow_visible {
  overflow: visible;
}
.overflow_x_scroll {
  overflow-x: scroll;
}
.overflow_y_scroll {
  overflow-y: scroll;
}
.overflow_ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.overflow_ellipsis wbr {
  display: none;
}
.no_bullets {
  list-style-type: none;
}
.rounded {
  border-radius: 2px;
}
.c-highlight {
  animation: 3s ease 0s 1 normal none c-highlight;
}
@keyframes c-highlight {
  from {
    background: rgba(242, 199, 68, 0.2);
  }
  to {
    background: #fff;
  }
}
.elevation_1 {
  box-shadow: 0 0 0 1px rgba(29, 28, 29, 0.13), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}
.elevation_2 {
  box-shadow: 0 0 0 1px rgba(29, 28, 29, 0.13), 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}
.elevation_3 {
  box-shadow: 0 0 0 1px rgba(29, 28, 29, 0.13), 0 18px 48px 0 rgba(0, 0, 0, 0.08);
}
.transparent {
  color: transparent;
}
.transparent_bg {
  background-color: transparent;
}

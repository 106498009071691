.sidenav {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 700;
  height: 100%;
  top: 0;
  bottom: 0;
  width: $sidenav-width;
  // padding-top: 10px;
  // border-right: solid 2px #fbf8f8;
  // padding-top: 50px;
  // padding-left: 5px;
  // background: #f8faff;
}

.sidenav,
.mat-drawer-inner-container {
  // background: #fafbfc;
}

.sidenav-toolbar {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  // align-items: center;
  position: sticky;
  padding: 1.3rem 16px 0;
  // background: #fff;
  // padding: 0 18.00450113px 0 24px;
  // height: 60px; // $toolbar-height;
  // border-bottom: solid #e7e7e7 1px;

  // overflow: hidden;
  // box-shadow: black 1px 1px 10px -10px;
  // border-bottom: solid #f3f3f3 1px;
  // background: white;

  .sidenav-toolbar-icon {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #ffe7d5;
    border-radius: 9px;
    // border: solid rgb(230, 230, 230) 1px;
  }
  .sidenav-toolbar-icon,
  .sidenav-toolbar-logo {
    // fill: rgba(0, 0, 0, 0.87);
    font-weight: 700;
    font-size: 1.1rem;
    font-family: Nunito, 'Courier New', Courier, monospace;
  }
  .sidenav-toolbar-icon {
    svg {
      width: 28px;
      height: 28px;
      padding: 2px;
    }
  }
  .sidenav-toolbar-logo {
    cursor: pointer;
    outline: 0;
    margin-left: 8px; // $sidenav-item-margin-left;
    // margin-bottom: -5px;
    flex: 1;
    svg {
      height: 30px;
      width: 60px;
    }
  }
  .sidenav-toolbar-collapse-toggle {
    transition: transform 0.25s;
    .sidenav-toolbar-collapse-icon {
      width: 12px;
      height: 12px;
      // font-size: 12px;
      line-height: 12px;
    }
  }
}

.sidenav-items {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

// .sidenav-items::-webkit-scrollbar {
//   display: none; /* Safari and Chrome */
// }

.sidenav-items {
  // height: calc(100vh - 60px);
  // overflow: auto;
  // padding-top: 70px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  // padding-left: .5rem;
  // padding-right: .5rem;
  // padding-top: 1.5rem;

  // border-bottom: solid #e7e7e7 1px;

  .subheading {
    margin-top: 10px;
    // margin-bottom: 12px;
    transition: 0.3s;
    color: #999a9b;
    // font-size: 13px;
    &.first-subheading {
      margin-top: 4px;
      margin-bottom: 6px;
    }
  }
  .sidenav-item {
    // height: 38px;
    // font-family: 'Segoe System UI Regular', 'Segoe UI Regular', sans-serif;
    padding: 18px 0;
    padding-left: 24px;
    padding-right: 16px;
    position: relative;
    cursor: pointer;
    outline: 0;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;
    // color: rgba(0, 0, 0, 0.87);
    color: rgba(33, 32, 32, 0.87);
    border-left: solid transparent 3px;
    transition: all 0.2s ease;

    .icon,
    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      font-size: 20px;
      line-height: 20px;
      // fill: $icon-normal;
      margin-right: $sidenav-item-margin-left;
    }
    svg {
      path,
      g {
        stroke: $icon-normal;
      }
    }
    .name {
      // padding-left: $sidenav-item-margin-left;
      // font-size: 16px;
      // font-weight: 400;
      // color: $icon-normal;
      // line-height: 1.2em;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
    }

    &.is-active {
      border-left: solid $primary-color 3px;
      background-color: #f8faff;
      .icon,
      .name {
        color: $icon-active;
      }
      .name {
        font-weight: 500;
      }
      svg {
        // fill: $icon-active;
        path,
        g {
          stroke: $icon-active;
        }
      }
    }

    &.is-nested {
      padding-left: 0;
      .icon,
      svg {
        // width: 12px;
        // height: 12px;
        // font-size: 12px;
        // line-height: 12px;
        // margin-left: 6px;
        color: #b9c5da;
        fill: #b9c5da;
      }
    }
  }
}

mat-form-field {
  width: 100%;
}

.mat-form-field-prefix {
  margin-right: 0.2rem;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}

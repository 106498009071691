ol.standard-list,
ul.standard-list {
  margin-left: 15px;
}

ol.standard-list {
  list-style-type: decimal;
}

ul.standard-list {
  list-style-type: disc;
}

.toolbar {
  position: relative;
  // position: sticky;
  //top: 0;
  //left: 0;
  //right: 0;
  z-index: 2;
  padding: 0 2%;
  background: white;
  border-bottom: solid #ebebeb 1.5px;
  // box-shadow: black 1px 1px 15px -15px;
  // background: #f7f7f7;
  .username {
    display: flex;
    align-items: center;
    cursor: pointer;
    .name {
      // font-size: 13px;
    }
    .image {
      margin-left: 10px;
      width: 25px;
      border-radius: 50%;
    }
  }
}

.toolbar-content {
  display: flex;
  justify-content: space-between;
  height: $toolbar-height;
  align-items: center;
}

.toolbar-brand {
  display: flex;
  align-items: center;
  height: 50px;
  svg {
    fill: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    font-size: 17px;
  }
}

.toolbar-brand-icon {
  width: 20px;
}

.toolbar-brand-name {
  font-size: 15px;
  font-weight: 400;
  color: black;
  padding-left: 15px;
}

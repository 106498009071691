@media print {
  //   .is-printing > * {
  //     display: none;
  //   }

  //   .is-printing print-layout {
  //     display: block;
  //   }

  canvas {
    width: 50px;
  }

  img {
    width: 100%;
  }

  .noprint,
  .noprint * {
    display: none !important;
  }
}

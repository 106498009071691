.panel-title {
  padding-top: 3px;
  margin-left: $sidenav-item-margin-left;
}

.mat-expansion-indicator::after {
  padding: 2px !important;
  margin-top: -5px !important;
  color: rgba(255, 255, 255, 0);
}

.mat-expansion-panel-header {
  padding: 0px !important;
}

.mat-expansion-panel-body {
  // padding: 0 0px 16px !important;
  padding: 0 0px 0px !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: rgba(0, 0, 0, 0) !important;
}

// .mat-expansion-panel-content {
//   padding: 0px 24px;
// }

.mat-expansion-indicator svg {
  width: 16px !important ;
  height: 16px !important;
}
